<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-row>
                        <v-col cols="12" sm="2" v-for="(key, i) in form" :key="i">
                            <v-switch v-if="!['data_grouping'].includes(i)" :label="formatKey(i)" v-model="form[i]" dense hide-details="" :false-value="0" :true-value="1"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
								v-model="form.data_grouping"
								:items="item_data_grouping"
								label="Data Grouping"
								item-text="text"
								item-value="value"
								outlined
								auto-select-first
								dense
								required
							></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: ['event_id'],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            tab: null,
            items:[],
            settings_form:[],
            form:{
                share_your_moments: null,
                star_of_the_night: null,
                activities: null,
                program_flow: null,
                event_sitemap: null,
                data_grouping: null,
            },
            monthsArray: [],
            currentSettings: [],
            isMounted: false,
            item_data_grouping: [
                { text: 'By Guest', value: 1 },
                { text: 'By Company', value: 2 },
            ],
        }
    },
    async mounted(){
        await this.getAllExpansionSettings();
        this.getFilters()
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_EVENT_ID',
            // 'GET_FORM_SETTINGS'
        ])
    },
    methods:{
        async getAllExpansionSettings(filter = null){
            this.initialSettings()
            this.loaded = false;
            let conditions = ["-", ":"]
            let payload = {
                type: 'Expansions',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
                this.currentSettings = res
                await Promise.all(res.map(async (e) => {
                    await Promise.all(Object.keys(this.form).filter(async (el) => {
                        if (el == e.parameter) {
                            if (conditions.some((el) => e.value.includes(el))) {
                                this.form[el] = e.value;
                            } else {
                                this.form[el] = parseFloat(e.value);
                            }
                        }
                    }));
                }));
                this.isMounted = true;
            })
        },
        getFilters(){
            let filters = {
                data: this.form,
                type:'Expansions'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
       formatKey(key) {
            let formattedKey = key.replace(/_/g, ' ');

            formattedKey = formattedKey.replace(/\b\w/g, function (match) {
                return match.toUpperCase();
            });

            return formattedKey;
        },
        initialSettings(){
            this.form = {
                share_your_moments: null,
                star_of_the_night: null,
                activities: null,
                program_flow: null,
                event_sitemap: null,
                data_grouping: null,
            }
        }
    },
    watch:{
        // GET_FORM_SETTINGS:{
        //     handler(val){
        //         this.items = val

        //         val.forEach(e=>{
        //             Object.keys(this.form).filter(el=>{
        //                 if(el == e.parameter){
        //                     this.form[el] = parseFloat(e.value)
        //                 }
        //             });
        //         })
        //     }
        // },
        form:{
            handler(val){
                this.getFilters()
            },
            deep:true
        },
        'form.data_grouping':{
            handler(val){
                let payload = {
                    url: 'checkSettingsByParameter',
                    parameter: 'data_grouping',
                    event_id: this.GET_EVENT_ID
                }
                this.$store.dispatch('urlPost', payload).then((res)=>{
                    if(val !== res){
                        this.$emit('raffleReset', true);
                    }else{
                        this.$emit('raffleReset', false);
                    }
                })
            }
        },
        GET_EVENT_ID:{
            async handler(val){
                if(val){
                    await this.getAllExpansionSettings();
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
</style>
