<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="overflow-x-hidden">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom">
                <span class="text-h5">Company Information</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 mt-1" fluid>
                    <v-row no-gutters class="">
                        <v-col cols="12" class="pa-0 mb-5" v-if="actions != 'Submit'">
                            <v-tooltip bottom color="#3788c6">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        :value="form.included_ticket_count == 0 ? false : true"
                                        :content="form.included_ticket_count"
                                        color="#f69f1a"
                                       
                                    >
                                        <v-btn
                                            @click="ticketDialog(true)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="#3788c6"
                                        >
                                            <v-icon>
                                                mdi-ticket-account
                                            </v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span>View Company Tickets</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.code"
                                :disabled="disabled_view"
                                :rules="errors.required"
                                label="Code"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.name"
                                :rules="errors.required"
                                :disabled="disabled_view"
                                label="Name"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="actions != 'Submit'">
                            <v-text-field
                                v-model="form.ticket_num"
                                :disabled="disabled_view"
                                label="Winning Ticket #"
                                outlined
                                readonly
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="actions != 'Submit'">
                            <v-text-field
                                v-model="form.prize_name"
                                :disabled="disabled_view"
                                label="Prize Name"
                                outlined
                                readonly
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2.5" class="pa-0">
                            <v-checkbox
                                v-model="form.include_raffle"
                                :disabled="disabled_view"
                                :true-value="1"
                                :false-value="0"
                                hide-details=""
                                label="Include in Raffle"
                                color="orange"
                                type="number"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="3" class="pa-0 mx-1" v-if="form.include_raffle">
                            <v-text-field
                                v-model="maxTicketNum"
                                :disabled="disabled_view"
                                label="Last Ticket Number"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pa-0 mx-1" v-if="form.include_raffle && actions == 'Update'">
                            <v-text-field
                                v-model="form.ticket_from"
                                :disabled="disabled_view"
                                label="Ticket From"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pa-0  mx-1" v-if="form.include_raffle && actions == 'Update'">
                            <v-text-field
                                v-model="form.ticket_to"
                                :disabled="disabled_view"
                                label="Ticket To"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pa-0" v-if="form.include_raffle">
                            <v-text-field
                                v-model="form.no_of_tickets"
                                :disabled="disabled_view"
                                @input="changeTicketTo()"
                                label="No. of Ticket"
                                type="number"
                                min="0"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                       
                        <v-expand-transition>
                            <v-col cols="3" class="ml-2" v-if="actions != 'View' && form.include_raffle">
                                <v-btn
                                    @click="generateTickets()"
                                >
                                    <span>Generate Ticket <v-icon>mdi-ticket-confirmation-outline</v-icon> </span>
                                </v-btn>
                                <v-list v-if="form.temp_company_tickets.length > 0">
                                    <v-card max-height="300" class="overflow-auto">
                                        <v-card-text class="pa-2">
                                            <v-row no-gutters v-for="(ticket, i) in form.temp_company_tickets" :key="i" class="pa-0 d-flex justify-content-center">
                                                <h3>
                                                    {{ ticket }}
                                                </h3>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-list>
                            </v-col>
                        </v-expand-transition>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #262626"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addCompany() : updateCompany()"
                            :disabled="disabled_view"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                   
                </v-row>
            </v-card-actions>
            <v-dialog v-model="company_tickets.dialog" width="40%" :retain-focus="false">
                <v-card class="overflow-x-hidden">
                    <v-card-title class="pa-3 pl-3 pb-0 border-bottom">
                        <span class="text-h5">Company Tickets</span>
                        <v-row class="m-0">
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                    text
                                    icon
                                    small
                                    color="gray"
                                    class="float-right"
                                    @click="ticketDialog(false)"
                                >
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters class="ma-3" v-if="actions == 'Update'">
                            <v-spacer></v-spacer>
                            <v-col cols="3">
                                <v-btn
                                    @click="includeExcludeTicket(1)"
                                    color="#ffa500"
                                >
                                <span style="color:black">Include Selected</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="3" class="ml-5">
                                <v-btn
                                    @click="includeExcludeTicket(2)"
                                    color="#f44336"
                                >
                                <span style="color:white">Exclude Selected</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table 
                            v-model="company_tickets.included_tickets"
							:headers="company_tickets.headers" 
							:items="form.company_tickets" 
                            :items-per-page="-1"
                            :show-select="actions == 'Update' ? true : false"
                            hide-default-footer
                            fixed-header
                            height="50vh"
                        >
                            <template v-slot:[`item.action`]="{item, index}">
                                <v-tooltip bottom color="red">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text icon color="red"
                                            v-if="actions == 'Update'"
											v-bind="attrs"
											v-on="on"
											>
											<v-icon
												class="btn-action"
												small
												@click="deleteTicket(item, index)"
												>mdi-delete</v-icon
											>
										</v-btn>
									</template>
									<span>Delete Ticket</span>
								</v-tooltip>
                            </template>
                            <template v-slot:[`item.included_in_raffle`]="{item}">
                                <span :class="`ml-1 badge badge-${item.included_in_raffle ? 'yes': 'no'}`">{{ item.included_in_raffle ? 'yes' : 'no' }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: false,
            company_tickets: {
                dialog: false,
                headers: [
                    { text: 'Ticket#', align:'start', value: 'ticket_num' },
                    { text: 'Prize',  align:'center', value: 'prize_name' },
                    { text: 'Included in Raffle',  align:'center', value: 'included_in_raffle' },
                    { text: 'Action',  align:'center', value: 'action' },
                ],
                items: [],
                included_tickets: []
            },
            maxTicketNum: null,
            settings:{
                ticket_length: 0,
            }
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_EVENT_ID"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getCompanyData();
                this.getMaxTicketNum();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getCompanyData();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getRaffleSettings()
    },
    methods: {
        async getRaffleSettings(){
            let payload = {
                type: 'Raffle',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.settings).filter(el=>{
                        if(el == e.parameter){
                            this.settings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
        },
        initialForm() {
            this.form = {
                code: '',
                name: '',
                no_of_tickets : 0,
                include_raffle: 0,
                temp_company_tickets: [],
                ticket_from: null,
                ticket_to: null,
                included_ticket_count: 0
            };
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async addCompany() {
            this.$refs.form.validate();
            let code_swal_text = this.form.code ? '' : 'Code';
            let name_swal_text = this.form.name ? '' : 'name';
            let array_text =
            [
                code_swal_text,
                name_swal_text,
            ]
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
             if (
                this.form.code &&
                this.form.name
            ){
                let payload = {
                    url: "companyRoute",
                    ...this.form,
                    event_id: this.GET_EVENT_ID	
                }
                
                await this.$store.dispatch('urlPost', payload).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit('NEW_MASTER_DATA', true);
                    this.$store.commit("DIALOG", false);
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                window.location.reload();
                            }
                        })
                    }
                })
            } else{
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }

        },
        async updateCompany() {
            if(this.form.include_raffle == 0 && (this.GET_DIALOG_VIEW.include_raffle != this.form.include_raffle && this.form.company_tickets.length > 0)){
                Swal.fire({
                    title: 'Are you sure you want to update?',
                    text: 'Company not included in raffle! Tickets will be deleted.',
                    icon: 'question',
                    showConfirmButton:true,
					confirmButtonText:'Confirm',
					showCancelButton:true,
					showCloseButton:true,
					confirmButtonColor: '397373',
					cancelButtonColor:'grey',
					reverseButtons:true,
                }).then((result)=>{
                    if(result.isConfirmed){
                        this.triggerUpdateNow();
                    }
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                window.location.reload();
                            }
                        })
                    }
                })
            }else{
                this.triggerUpdateNow();
            }
        },
        async triggerUpdateNow(){
            let payload = {
                url: "companyRoute",
                id: this.form.id,
                ...this.form,
                event_id: this.GET_EVENT_ID	
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
                Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
                this.closeDialog();
            });
        },
        async getCompanyData() {
            this.form.id                            = this.GET_DIALOG_VIEW.id;
            this.form.code                          = this.GET_DIALOG_VIEW.code;
            this.form.name                          = this.GET_DIALOG_VIEW.name;
            this.form.no_of_tickets                 = this.GET_DIALOG_VIEW.no_of_tickets;
            this.form.include_raffle                = parseInt(this.GET_DIALOG_VIEW.include_raffle);
            this.form.company_tickets               = this.GET_DIALOG_VIEW.company_tickets;
            this.form.included_ticket_count         = this.GET_DIALOG_VIEW.company_tickets ? this.GET_DIALOG_VIEW.company_tickets.filter((x) => x.included_in_raffle == 1).length : 0;
            if(this.GET_DIALOG_VIEW.raffle_prize_details){
                this.form.ticket_num                    = this.GET_DIALOG_VIEW.raffle_prize_details.ticket_num;
                this.form.prize_name                    = this.GET_DIALOG_VIEW.raffle_prize_details.prize_name;
            }
            this.form.new_ticket                    = false;
            this.form.last_ticket_number            = this.GET_DIALOG_VIEW.last_ticket_number;
        },
        uploadImage(event) {
            let that = this;
			let reader = new FileReader()
			reader.addEventListener('load', () => {
				that.form.flow_image = reader.result
			})
			let file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        async generateTickets(){
            this.form.temp_company_tickets = []
            if(await this.checkTicketRange()){
                if(this.form.no_of_tickets == 0){
                    Swal.fire({
                        title: 'Cannot generate 0 ticket!',
                        text: 'Please indicate No. of Ticket ',
                        icon: 'warning'
                    })
                    return false
                }
                let ticket_to_generate = this.form.ticket_to - this.form.ticket_from;

                if(ticket_to_generate + 1 + this.form.company_tickets.length <= this.form.no_of_tickets){
                    let payload = {
                        url: 'generateCompanyTicket',
                        ...this.form
                    }
                    this.$store.dispatch('urlPost', payload).then((response)=>{
                        if(response.data){
                            this.form.temp_company_tickets = response.data;
                            this.form.new_ticket = true;
                        }
                        // Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    }).catch((error)=>{
                        if(error.response.status == 401){
                            Swal.fire({
                                title: 'Unauthenticated!',
                                text: '',
                                icon: 'error',
                                allowOutsideClick: false
                            }).then((result)=>{
                                if(result.isConfirmed){
                                    localStorage.removeItem("token");
                                    window.location.reload();
                                }
                            })
                        }
                    })
                }else{
                    Swal.fire({
                        title: 'Tickets to generate is greater than number of tickets!',
                        text:   '',
                        icon: 'warning'
                    });
                    return false;
                }
            }
        },
        ticketDialog(value){
            this.company_tickets.dialog = value
            if(value){
                this.company_tickets.included_tickets = [];
            }
        },
        deleteTicket(item, index){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ticket# ${item.ticket_num}?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url: 'deleteTicket',
                        id: item.id,
                        event_id: this.GET_EVENT_ID
                    }
                    this.$store.dispatch('urlDelete', payload).then((res)=>{
                        this.form.company_tickets.splice(index, 1)
                        Swal.fire(
                            res.message,
                            "",
                            res.icon
                        )
                    });
                }
            });
        },
        async getMaxTicketNum(){
            let payload = {
                url: 'getMaxTicketNum'
            }
            await this.$store.dispatch('urlPost', payload).then((res)=>{
                let no_of_ticket = this.form.no_of_tickets;
                this.form.ticket_from = res;
                this.form.ticket_to = no_of_ticket == null ? "0".padStart(this.settings.ticket_length, "0") : (parseInt(res) + parseInt(no_of_ticket) - 1).toString().padStart(this.settings.ticket_length, "0");
                this.maxTicketNum = res;
            })
        },
        async checkTicketRange(){
            return new Promise((resolve, reject)=>{
                let tickets = [];
                let ticket_to_generate = this.form.ticket_to - this.form.ticket_from;
                let current_ticket = this.form.ticket_from;
                for (let i = 0; i <= ticket_to_generate; i++) {
                    let ticketNumber = current_ticket.toString().padStart(this.settings.ticket_length, '0');
                    tickets.push(ticketNumber);
                    current_ticket++;
                }
    
                let payload = {
                    url: 'checkCompanyTicketExists',
                    company_tickets: tickets,
                    company_id: this.form.id,
                }
                this.$store.dispatch('urlPost', payload).then((res)=>{
                    if(res.code == 200){
                        resolve(true)
                    }else{
                        Swal.fire({
                            title: res.message,
                            html: res.existing_tickets.join(' <br>'),
                            icon: 'warning'
                        })
                         resolve(false)
                    }
                })

            })
        },
        changeTicketTo(){
            let no_of_ticket = this.form.no_of_tickets;
            this.form.ticket_to =  (parseInt(this.form.ticket_from) + parseInt(no_of_ticket) - 1).toString().padStart(this.settings.ticket_length, "0")
        },
        includeExcludeTicket(action){
            let included_ticket_ids = this.company_tickets.included_tickets.map(e => e.id)
            let payload = {
                url: 'includeExcludeTicket',
                included_ticket_ids: included_ticket_ids,
                company_id: this.form.id,
                action: action
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                this.form.company_tickets = res.data;
                this.form.included_ticket_count = res.data.filter((x) => x.included_in_raffle == 1).length;
                this.$forceUpdate();
                Swal.fire(
                    res.message,
                    "",
                    res.icon
                )
            });
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }else{
                    this.getMaxTicketNum();
                }
            },
        },
        form:{
            handler(val){
                
            },
            deep: true
        }
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }

    .badge-yes{
        padding: .3em .6em .3em;
        font-weight: 500;
        background-color: #7ee3ff !important;
        color: black !important;
    }
    .badge-no{
        padding: .3em .6em .3em;
        font-weight: 500;
        background-color: #ff8585 !important;
        color: black !important;
    }
</style>
