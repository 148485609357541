<template>
    <v-card>
        <v-container>
            <!-- <v-tabs v-model="tab"  id="group-tab" background-color="#e59400">
				<v-tabs-slider color="#000000"></v-tabs-slider>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">System Toolbar Settings</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Company xList</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Raffle Prizes</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Activites</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Program Flow</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Event Nominees</span>
				</v-tab>
				<v-tab ripple class="underline-animation-black">
					<span style="font-family: Evogria; color:black;">Event Sitemap</span>
				</v-tab>
				<v-spacer></v-spacer>
				<v-tabs-items v-model="tab" touchless>
					<v-tab-item>
						<ModuleSettingsTableComponentVue/>
					</v-tab-item>
				</v-tabs-items>
			</v-tabs> -->
            <v-row no-gutters class="pa-0">
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Font-family Primary</h4>
                    <v-autocomplete
                        v-model="form.system_font_family_primary"
                        :items="GET_ALL.font_family"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Toolbar Image</h4>
                        <v-card color="white">
                            <v-container fluid class="picture" v-if="!hasUploaded">
                                <v-img :src="checkImagePath(preview.toolbar_image)" contain aspect-ratio="1.7">
                                </v-img>
                            </v-container>
                            <v-container fluid class="picture" v-else v-for="(image, i) in preview.toolbar_image" :key="i">
                                <v-img :src="image" contain aspect-ratio="1.7">
                                </v-img>
                            </v-container>
                        </v-card>
                    <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_toolbar_image'" :previewKey="'toolbar_image'" :showPreview="true" @reset="clearFileUpload" />
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Landing Image</h4>
                        <v-card color="white">
                            <v-container fluid class="picture" v-if="!hasUploadedLanding">
                                <v-img :src="checkImagePath(preview.landing_image)" contain aspect-ratio="1.7">
                                </v-img>
                            </v-container>
                            <v-container fluid class="picture" v-else v-for="(image, i) in preview.landing_image" :key="i">
                                <v-img :src="image" contain aspect-ratio="1.7">
                                </v-img>
                            </v-container>
                        </v-card>
                    <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_landing_image'" :previewKey="'landing_image'" :showPreview="true" @reset="clearFileUpload" />
                </v-col>
            </v-row>
            <v-row no-gutters class="pa-0">
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Toolbar Color</h4>
                    <v-color-picker
                        v-model="form.system_toolbar_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Toolbar Font Color</h4>
                    <v-color-picker
                        v-model="form.system_toolbar_font_color_main"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Toolbar Icon Color</h4>
                    <v-color-picker
                        v-model="form.system_toolbar_icon_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Main Label Color</h4>
                    <v-color-picker
                        v-model="form.system_main_label_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Secondary Label Color</h4>
                    <v-color-picker
                        v-model="form.system_secondary_label_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Guest Information Color</h4>
                    <v-color-picker
                        v-model="form.system_guest_information_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Log Out Dotted Color</h4>
                    <v-color-picker
                        v-model="form.border_dotted_log_out"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Log Out Text Button Color</h4>
                    <v-color-picker
                        v-model="form.system_font_color_secondary_log_out"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Main Icon Color</h4>
                    <v-color-picker
                        v-model="form.icon_color_main"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Log In Button Text Color</h4>
                    <v-color-picker
                        v-model="form.log_in_button_text_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Submit Button Text Color</h4>
                    <v-color-picker
                        v-model="form.submit_button_text_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Window Action Button Color</h4>
                    <v-color-picker
                        v-model="form.window_action_button_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Carousel Action Button Text Color</h4>
                    <v-color-picker
                        v-model="form.window_action_button_text_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Carousel Navigate Button</h4>
                    <v-color-picker
                        v-model="form.window_carousel_navigate_button_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Carousel Navigate Button Text Color</h4>
                    <v-color-picker
                        v-model="form.window_carousel_navigate_text_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Body Color</h4>
                    <v-color-picker
                        v-model="form.system_body_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>Expansion Title Color</h4>
                    <v-color-picker
                        v-model="form.expansion_title_color"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="2" class="ml-2">
                    <h4>System Dialog Border Color</h4>
                    <v-color-picker
                        v-model="form.system_dialog_border"
                        mode="hexa"
                    ></v-color-picker>
                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import moment from 'moment';
import FileUploadComponent from '@/components/shared/FileUploadComponent.vue'
import SharedFunctionsComponentVue from '@/components/shared/SharedFunctionsComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['event_id'],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            tab: null,
            items:[],
            settings_form:[],
            job_title_selection :[],
            form:{
                system_toolbar_color: '',
                system_toolbar_font_color_main: '',
                border_dotted_log_out: '',
                system_font_color_secondary_log_out: '',
                system_font_family_primary: '',
                system_toolbar_icon_color: '',
                system_main_label_color: '',
                system_secondary_label_color: '',
                system_guest_information_color: '',
                log_in_button_text_color: '',
                icon_color_main: '',
                submit_button_text_color: '',
                window_action_button_color: '',
                window_action_button_text_color: '',
                window_carousel_navigate_button_color: '',
                system_body_color: '',
                expansion_title_color: '',
                system_dialog_border: '',
                window_carousel_navigate_text_color: '',
                system_toolbar_image: null,
                system_landing_image: null,
            },
            monthsArray: [],
            currentSettings: [],
            isMounted: false,
            votingIsDisabled: false,
            selected_key: null,
            tab: 0,
            preview:{
                landing_image: null,
                toolbar: null,
            },
            hasUploaded: false,
            hasUploadedLanding: false,
        }
    },
    async mounted(){
        await this.getAllPreferenceSettings();
        this.getFilters()
        this.checkEndNomination();
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_NEW_DATA',
            'GET_EVENT_ID',
            'GET_ALL'
            // 'GET_FORM_SETTINGS'
        ])
    },
    components:{
        FileUploadComponent
    },
    methods:{
        async getAllPreferenceSettings(filter = null){
            this.initialSettings()
            this.loaded = false;
            this.hasUploaded = false;
            this.hasUploadedLanding = false;
            let conditions = ["-", ":"]
            let payload = {
                type: 'Preferences',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
                this.currentSettings = res
                await Promise.all(res.map(async (e) => {
                    await Promise.all(Object.keys(this.form).filter(async (el) => {
                        if (el == e.parameter) {
                            if (conditions.some((el) => e.value.includes(el))) {
                            this.form[el] = e.value;
                            } else {
                            this.form[el] = e.value;
                            }
                        }
                    }));
                }));
                this.isMounted = true;
            })
        },
        getFilters(){
            let filters = {
                data: this.form,
                type:'Preferences'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
        checkEndNomination(){
            let payload = {
                url: 'checkSettingsByParameter',
                parameter: 'nomination_end_date',
                event_id: this.event_id
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                if(res == 0){
                    this.votingIsDisabled = true;
                }else{
                    this.votingIsDisabled = false;
                }
            })
        },
        uploadedData(data) {
            if (!data) {
                this.form.system_toolbar_image = null;
            }
            this.form[data.obj_key] = data.attachments;
            this.preview[data.previewKey] = data.attachments[0]
            switch(data.obj_key){
                case 'system_toolbar_image':
                    this.hasUploaded =  true;
                    break;
                case 'system_landing_image':
                    this.hasUploadedLanding = true;
                    break;
            }
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.system_toolbar_image = null;
            }
            this.form.system_toolbar_image = null;
            this.$refs.fileUpload.reset();
            this.checkSavedToolbarImage()
        },
        checkSavedToolbarImage(){
            let payload = {
                url: 'checkSettingsByParameter',
                parameter: 'system_toolbar_image',
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                this.form.system_toolbar_image = res
            })
            this.hasUploaded = false
        },
        initialSettings(){
            this.form = {
                system_toolbar_color: '',
                system_toolbar_font_color_main: '',
                border_dotted_log_out: '',
                system_font_color_secondary_log_out: '',
                system_font_family_primary: '',
                system_toolbar_icon_color: '',
                system_main_label_color: '',
                system_secondary_label_color: '',
                system_guest_information_color: '',
                log_in_button_text_color: '',
                icon_color_main: '',
                submit_button_text_color: '',
                window_action_button_color: '',
                window_action_button_text_color: '',
                window_carousel_navigate_button_color: '',
                system_body_color: '',
                expansion_title_color: '',
                system_dialog_border: '',
                window_carousel_navigate_text_color: '',
                system_toolbar_image: null,
                system_landing_image: null,
            }
        }
    },
    watch:{
        // GET_FORM_SETTINGS:{
        //     handler(val){
        //         this.items = val

        //         val.forEach(e=>{
        //             Object.keys(this.form).filter(el=>{
        //                 if(el == e.parameter){
        //                     this.form[el] = parseFloat(e.value)
        //                 }
        //             });
        //         })
        //     }
        // },
        form:{
            handler(val){
                this.getFilters()
            },
            deep:true
        },
        'form.system_toolbar_image':{
            handler(val){
                this.preview.toolbar_image = val;
            }
        },
        'form.system_landing_image':{
            handler(val){
                this.preview.landing_image = val;
            }
        },
        GET_NEW_DATA:{
            handler(val){
                if(val){
                    this.getAllPreferenceSettings();
                    this.$store.commit('NEW_DATA', false);
                }
            }
        },
        GET_EVENT_ID:{
            async handler(val){
                if(val){
                    await this.getAllPreferenceSettings();
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
</style>
