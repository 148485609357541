<template>
    <v-card v-model="valid" lazy-validation class="overflow-hidden" height="65vh"  outlined style="border:white; position:relative;" fluid color="#000000">
        <v-img style="position:absolute;" src="../../../../assets/LED_WALL_BG.jpg" height="inherit" position="center center"></v-img>
        <!-- <v-card-text> -->
            <v-row ref="scrollRow" class="d-flex justify-content-center position-absolute">
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                    {{actions}}
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="text-center mt-2">
                                <h1 style="font-size:50px;">Congratulations To Our Raffle Draw Winner!</h1>
                            </v-col>
                            <!-- <v-col class="text-center text-white">
                                <h5>{{ formatDate(data.birth_date) }}</h5>
                            </v-col> -->
                            <v-col cols="12" class="text-center text-white mt-2">
                                    <v-row v-if="form.suspense_animate == 0">
                                        <v-col cols="12" class="text-center text-white">
                                            <v-img src="../../../../assets/RaffleTicket.png" height="100" class="text-center"  contain position="center center">
                                                <h1 style="font-size:70px;" class="refNum" >{{ form.refNumber }}</h1>
                                            </v-img>
                                        <!-- <h2 class="mt-5" style="font-size:40px;"> from {{ form.company }} </h2> -->
                                        </v-col>
                                    </v-row>
                                    <v-img src="../../../../assets/RaffleTicket.png" height="120" class="d-flex" contain  v-else>
                                        <v-row no-gutters class="d-flex justify-content-center align-items-center mt-5">
                                            <v-col cols="1" v-for="(text, index) in form.refSuspense" :key="index" class="pa-0 ml-5">
                                                <v-img
                                                    v-if="!text.shown"
                                                    src="@/assets/question.png"
                                                    height="75"
                                                    width="75"
                                                    contain
                                                    style="cursor:pointer"
                                                    @click="setShow(index)"
                                                ></v-img>
                                                <v-expand-transition>
                                                    <v-card v-if="text.shown" flat class="gradient_card">
                                                        <span style="font-size:50px; color:black; font-family: 'Kaushan Script', cursive;" class="text-font-h4 font-weight-bold pr-2">
                                                        {{ text.number }}
                                                        </span>
                                                    </v-card>
                                                </v-expand-transition>
                                            </v-col>
                                        </v-row>
                                    </v-img>
                            </v-col>
                            <v-expand-transition>
                                <v-col cols="12" class="text-center text-white" v-if="allShown">
                                    <!-- <h2 style="font-size:40px;"> from {{ form.company }}
                                    </h2> -->
                                </v-col>
                            </v-expand-transition>
                            <v-col cols="12" class="text-center">
                                <h2 style="font-size:35px;"> for winning 
                                    <h2 style="font-size:50px;">  {{ ' '+ form.prize_name ? form.prize_name.toUpperCase(): '' }}
                                    </h2>
                                </h2>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        <!-- </v-card-text> -->
        <!-- <v-btn color="#000000">
            <span style="color:#ffa500">
                next
            </span>
        </v-btn> -->
        
    </v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import confetti from "canvas-confetti";

export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: false,
            allShown: false
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW"
        ]),
        actions() {
            this.getRafflePrizeData();
            return ""
        },
        refNumberLength() {
        return this.form.refNumber.length * 30;
        },
        companyLength() {
        return this.form.company.length ;
        },
        refNumberFontSize() {
        return `${Math.max(72 - this.refNumberLength * 2, 24)}px`; // Adjust the size based on length
        },
        refNumberImageHeight() {
            console.log(this.refNumberLength)
        return `${Math.max( this.refNumberLength * 2, 100)}px`; // Adjust height based on length
        },
        companyImageHeight() {
        return `${Math.max(this.companyLength * 2, 100)}px`; // Adjust height based on length
        }
    },
    created() {
        this.initialForm();
    },
    mounted() {
         window.addEventListener('keyup', this.handleKeyPress);
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleKeyPress);
    },
    methods: {
        initialForm() {
            this.form = {
                code        : '',
                name        : '',
                order       : '',
                prize_image : ''
            };
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async getRafflePrizeData() {
            this.form.full_name     = this.GET_DIALOG_VIEW.full_name;
            this.form.prize_image   = this.GET_DIALOG_VIEW.prize_image;
            this.form.prize_name   = this.GET_DIALOG_VIEW.prize_name;
            this.form.company   = this.GET_DIALOG_VIEW.company;
            this.form.refNumber   = this.GET_DIALOG_VIEW.refNumber;
            this.form.suspense_animate   = this.GET_DIALOG_VIEW.suspense_animate;
            if(this.GET_DIALOG_VIEW.suspense_animate){
                this.form.refSuspense = this.form.refNumber.split('');
                this.form.refSuspense = this.form.refSuspense.map((x)=>{
                    return {
                        number: x,
                        shown: 0
                    }
                })
            }
        },
        setShow(i){
            if (this.form.refSuspense[i] !== undefined) {
                this.form.refSuspense[i].shown = !this.form.refSuspense[i].shown;
                let shownCount = this.form.refSuspense.filter(x=>x.shown == 1).length;
                if(shownCount == this.form.refSuspense.length){
                    this.confetti();
                    this.allShown = true;
                }
                this.$forceUpdate();
            } 
        },
        confetti() {
            let duration = 15 * 1000,
                animationEnd = Date.now() + duration,
                defaults = {
                startVelocity: 20,
                spread: 360,
                ticks: 90,
                zIndex: 9999,
            };
            let interval = setInterval(function () {
                let timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                let particleCount = 80 * (timeLeft / duration);
                function randomInRange(min, max) {
                    return Math.random() * (max - min) + min;
                }

                // since particles fall down, start a bit higher than random
                confetti(
                    Object.assign({}, defaults, {
                        particleCount: 300,
                        colors: ["#bb0000", "#fef1aa"],
                        shapes: ['circle', 'circle', 'square'],
                        origin: {
                            x: randomInRange(0.1, 0.3),
                            y: Math.random() - 0.2,
                        },
                    })
                );
                confetti(
                    Object.assign({}, defaults, {
                        particleCount: 300,
                        colors: ["#bb0000", "#fef1aa"],
                        shapes: ['circle', 'circle', 'square'],
                        origin: {
                            x: randomInRange(0.7, 0.9),
                            y: Math.random() - 0.2,
                        },
                    })
                );
            }, 250);
		},
        handleKeyPress(event) {
            if(this.form.suspense_animate){
                if(!isNaN(event.key)){
                    this.setShow(event.key - 1)
                }
            }
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                    this.allShown = false;
                }
            },
        },
    },
};
</script>
<style scoped>

    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
    h4 ,h1 , h5, h2 {
        font-family: 'Kaushan Script', cursive;
        background: -webkit-linear-gradient(#ae6901, #eddb77);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .myclass.v-sheet.v-card {
        border: 3px solid #ffa500 !important;
    }

    .gradient_card {
        background: -webkit-linear-gradient(#64510C, #FFCA05, #64510C);
    }

    .fade-enter-active, .fade-leave-active,
    .slide-fade-enter-active, .slide-fade-leave-active {
        transition: opacity 0.5s, transform 0.5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        opacity: 0;
        transform: translateY(-20px);
    }

    .image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cover-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
