<template>
    <v-main style="background-color: black;">
        <v-container fluid>
            <v-row class="system_toolbar d-flex justify-content-between flex-wrap">
                <div>
                    <v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
					    <v-img
						max-height="50"
						max-width="190"
						src="@/assets/ingcoph_logo_alt.png"
						style="margin-left: -5px"
						class="logo-img"
						></v-img>                 
					</v-tabs>
                </div>
            </v-row>
        </v-container>
		<!-- <v-row justify="center" class="pa-9">
							
			<span style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
				<label class="g-mb-10" style="color:white">OR</label>
			</span>

	</v-row> -->
        <div fluid class="d-flex justify-content-center">
			<div class="col-md-10 col-lg-6 col-sm-10 g-mt-10">

					<div class="row" v-if="settings.data_grouping == 1 ">
						<div class="col-lg-12 col-sm-12 g-pa-2">
							<div class="form-group g-mb-20">
								<v-form>
									<v-row>
										<v-text-field
											@keyup.enter="submitReferenceNumber" 
											label="ENTER REFERENCE NUMBER"
											persistent-hint
											regular
											tile
											outlined	
											color="orange"	
											class="fill-width"
											v-model="referenceNumber">
										</v-text-field>
										<v-btn @click="submitReferenceNumber" color="orange" class="mx-auto" xs12 sm6 dark solo x-large>
											Enter
										</v-btn>
										<!-- <v-autocomplete label="ENTER REFERENCE NUMBER " color="orange" dark autofocus outlined v-model="referenceNumber" :items="registered_participants"></v-autocomplete>
										<v-btn @click="submitReferenceNumber" color="orange" class="mx-auto" xs12 sm6 dark solo x-large >
											Enter
										</v-btn> -->
									</v-row>
										<!-- <v-row justify="center">
								
												<span style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
													<label class="g-mb-10" style="color:white">OR</label>
												</span>
									
										</v-row>
										<v-row justify="center">
											<v-dialog v-model="uploadModalOpen" max-width="350" >
												<template v-slot:activator="{ on, attrs }">
													<v-btn @click="clearReferenceNumber" elevation="24" color="orange" icon rounded fab plain v-bind="attrs" v-on="on">
														<v-icon size="35" left>mdi-qrcode-scan</v-icon>
														<span class="underline-animation" style="font-family: Evogria; font-size: 1.8rem; font-weight: bold;">SCAN QR to Confirm</span>
													</v-btn>
												</template>
												<v-card style="font-family: Evogria;" class="gray-border">
													<v-card-title  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
														SCAN YOUR QR CODE
													</v-card-title>
													<v-divider :thickness="20"></v-divider>
													<v-card-text>Choose an action:</v-card-text>
													<v-card-text class="text-center"  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
														<input @change="handleFileUpload" ref="fileInput" type="file" accept="image/*" style="display: none" />
														<v-btn @click="chooseFile" class="underline-animation">
															<v-icon size="22" left>mdi-paperclip</v-icon> 
															Upload QR Code
														</v-btn>
													</v-card-text>
													<v-spacer></v-spacer>
													<v-card-text class="text-center">
														OR
													</v-card-text>
													<v-spacer></v-spacer>
													<v-card-text class="text-center"  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
														<v-btn @click="useCamera" class="underline-animation">
															<v-icon size="22" left>
																mdi-camera
															</v-icon>
																Use Camera
														</v-btn>
													</v-card-text>
												</v-card>
												<v-card id="qr-code-full-region"></v-card>
												<v-btn class="orange-border" v-if="qrScannerRunning" @click="closeQRScanner" color="orange" dark >Close Scanner</v-btn>
											</v-dialog>
										</v-row> -->
								</v-form>
							</div>
						</div>
					</div>
					<div class="row" v-if="settings.data_grouping == 2 ">
						<div class="col-lg-12 col-sm-12 g-pa-2">
							<div class="form-group g-mb-20">
							<v-form>
								<v-row>
									<v-autocomplete
										v-model="company_id"
										label="ENTER COMPANY NAME"
										@keyup.enter="filterGuests()" 
										@change="filterGuests()"
										:items="dropdowns.company_list"
										item-text="name"
										item-value="id"
										color="orange"	
										class="fill-width"
										persistent-hint
										regular
										tile
										outlined	
									>
									</v-autocomplete>
									<v-autocomplete
										v-if="companySearched"
										v-model="referenceNumber"
										:loading="loading"
										:items="items"
										:search-input.sync="search"
										item-text="full_name"
										item-value="employee_id"
										label="ENTER DETAILS"
										cache-items
										hide-no-data
										hide-details
										color="orange"	
										class="fill-width"
										persistent-hint
										regular
										tile
										outlined		
									>
									</v-autocomplete>
									<v-btn  v-if="companySearched" @click="submitReferenceNumber" color="orange" class="mx-auto" xs12 sm6 dark solo x-large>
										Enter
									</v-btn>
									<!-- <v-autocomplete label="ENTER REFERENCE NUMBER " color="orange" dark autofocus outlined v-model="referenceNumber" :items="registered_participants"></v-autocomplete>
									<v-btn @click="submitReferenceNumber" color="orange" class="mx-auto" xs12 sm6 dark solo x-large >
										Enter
									</v-btn> -->
								</v-row>
									<!-- <v-row justify="center">
							
											<span style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
												<label class="g-mb-10" style="color:white">OR</label>
											</span>
								
									</v-row>
									<v-row justify="center">
										<v-dialog v-model="uploadModalOpen" max-width="350" >
											<template v-slot:activator="{ on, attrs }">
												<v-btn @click="clearReferenceNumber" elevation="24" color="orange" icon rounded fab plain v-bind="attrs" v-on="on">
													<v-icon size="35" left>mdi-qrcode-scan</v-icon>
													<span class="underline-animation" style="font-family: Evogria; font-size: 1.8rem; font-weight: bold;">SCAN QR to Confirm</span>
												</v-btn>
											</template>
											<v-card style="font-family: Evogria;" class="gray-border">
												<v-card-title  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
													SCAN YOUR QR CODE
												</v-card-title>
												<v-divider :thickness="20"></v-divider>
												<v-card-text>Choose an action:</v-card-text>
												<v-card-text class="text-center"  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
													<input @change="handleFileUpload" ref="fileInput" type="file" accept="image/*" style="display: none" />
													<v-btn @click="chooseFile" class="underline-animation">
														<v-icon size="22" left>mdi-paperclip</v-icon> 
														Upload QR Code
													</v-btn>
												</v-card-text>
												<v-spacer></v-spacer>
												<v-card-text class="text-center">
													OR
												</v-card-text>
												<v-spacer></v-spacer>
												<v-card-text class="text-center"  @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }">
													<v-btn @click="useCamera" class="underline-animation">
														<v-icon size="22" left>
															mdi-camera
														</v-icon>
															Use Camera
													</v-btn>
												</v-card-text>
											</v-card>
											<v-card id="qr-code-full-region"></v-card>
											<v-btn class="orange-border" v-if="qrScannerRunning" @click="closeQRScanner" color="orange" dark >Close Scanner</v-btn>
										</v-dialog>
									</v-row> -->
							</v-form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-expansion-panels v-model="showInfoPanel" v-if="(isTextFieldNotEmpty || showInfoPanel !== 0) && settings.data_grouping == 1">
			<v-expansion-panel class="small-header">
				<!-- <v-expansion-panel-header color="black">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Information
					</span>
				</v-expansion-panel-header> -->
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1rem; font-weight: bold;" class="justify-content-center">
					<div style="font-family: Evogria;">
						<!-- <v-divider :thickness="50"></v-divider> -->
						<v-card-title style="justify-content: center; min-height: auto; font-size: 1.5rem;" class="adaptive-text">
							Guest Information
						</v-card-title>
						<v-divider :thickness="50"></v-divider>
						<div style="display: flex; justify-content: center;">
							<div style="width: 100%; justify-content: center;" class="adaptive-text">
								<v-card-text>
						
								</v-card-text>
								<v-card-text>
									<v-divider :thickness="50"></v-divider>
									<div class="flex-container adaptive-text">
										<v-icon size="40" left class="align-end">mdi-account-check</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">Name: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="selectedEmployeeName" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
											<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
										</svg>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;&nbsp;Company Name: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedCompanyName" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="40" left>mdi-ticket-confirmation-outline</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;Reference Number: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedEmployeeName" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="40" left>mdi-chair-rolling</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;Table Number: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedTable" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="40" left>mdi-silverware-variant</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;Buffet Table: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedBuffet" color="orange"></v-text-field>
									</div>
								</v-card-text>
								
								<!-- <v-card-text>
									<div class="flex-container adaptive-text">
										<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
										<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
									</svg>
										<span style="font-size: 17px;">&nbsp;Company Name:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedCompanyName" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
					<div class="flex-container adaptive-text" style="font-family: Evogria;">
						<v-icon size="35" left>mdi-ticket-confirmation-outline</v-icon>
						<span style="font-size: 17px;">Reference Number:</span>
						<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedEmployeeName" color="orange"></v-text-field>
					</div>
				</v-card-text>

								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-chair-rolling</v-icon>
										<span style="font-size: 17px;">Table Number:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedTable" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-silverware-variant</v-icon>
										<span style="font-size: 17px;">Buffet Table:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedBuffet" color="orange"></v-text-field>
									</div>
								</v-card-text> -->
								<!-- <v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-glass-mug-variant</v-icon>
										<span style="font-size: 17px;">Mobile Bar Ticket:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="formattedMobileBar" color="orange"></v-text-field>
									</div>
								</v-card-text> -->
								<!-- <v-card-text class="adaptive-text">
									<v-icon size="40" left>mdi-account-check</v-icon> Name: &nbsp; <span class="orange-text">{{ employeeName }}</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text>
								<v-card-text class="adaptive-text">
									<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
										<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
									</svg> Company: &nbsp; <span class="orange-text">{{ company_name }}</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text>
								<v-card-text class="adaptive-text">
									<v-icon size="40" left>mdi-ticket-confirmation-outline</v-icon> Reference Number: &nbsp; <span class="orange-text">#{{ employeeId }}</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text>
								<v-card-text class="adaptive-text">
									<v-icon size="40" left>mdi-chair-rolling</v-icon>Table Number: &nbsp; <span class="orange-text">Table {{ tableNumber }}</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text>
								<v-card-text class="adaptive-text">
									<v-icon size="40" left>mdi-silverware-variant</v-icon>Buffet Table: &nbsp; <span class="orange-text">Table {{ buffetTable }}</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text>
								<v-card-text class="adaptive-text">
									<v-icon size="40" left>mdi-glass-mug-variant</v-icon>Mobile Bar Ticket: &nbsp; <span class="orange-text">{{ mobileBarTicket }} / 2</span>
									<v-divider :thickness="50"></v-divider>
								</v-card-text> -->
							</div>
							<!-- <div style="width: 50%;" v-if="tab">
								<v-card-text class="d-flex align-start adaptive-text">
									<v-icon size="30" left>mdi-qrcode-plus</v-icon>
									<span>QR Code:</span>
									<v-spacer></v-spacer> -->
								<!-- <v-img 
									src="@/assets/qr.png"
									alt="Placeholder"
									max-width="40%"
									max-height="auto"
									style="float: center; margin-right: 30%;"
									></v-img> -->
									<!-- <img :src="qrImage" alt="QR Code" style="width: 350px; height: auto; float: center; margin-right: 30%;" /> -->
								<!-- </v-card-text>
								<v-divider :thickness="50"></v-divider>
								<div class="d-flex flex-column text-center">
									<v-card-text style="font-style: italic; color: orange; ">
										<span>"</span>
										<span class="Adaptive-text-Note" style="text-decoration: underline;">Note: Please save QR code to show in mobile bar</span>
										<span>"</span>
									</v-card-text>
									<v-btn @click="downloadQR" class="orange-border underline-animation-black" @mouseover="hovered = true" @mouseleave="hovered = false" :class="{ 'orange-text': hovered }" color="orange" small dark>
										<span style="color: black;"><v-icon size="24" left>mdi-download-box</v-icon>Download QR</span>
									</v-btn>
								</div> -->
							<!-- </div> -->
						</div>
						<v-divider :thickness="50"></v-divider>
					</div>  
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-expansion-panels v-model="showInfoPanel" v-if="(companySearched) && settings.data_grouping == 2">
			<v-expansion-panel class="small-header">
				<!-- <v-expansion-panel-header color="black">
					<span class="text-center" style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
						Information
					</span>
				</v-expansion-panel-header> -->
				<v-expansion-panel-content color="black" style="font-family: Evogria; font-size: 1rem; font-weight: bold;" class="justify-content-center">
					<div style="font-family: Evogria;">
						<!-- <v-divider :thickness="50"></v-divider> -->
						<v-card-title style="justify-content: center; min-height: auto; font-size: 1.5rem;" class="adaptive-text">
							Guest Information
						</v-card-title>
						<v-divider :thickness="50"></v-divider>
						<div style="display: flex; justify-content: center;">
							<div style="width: 100%; justify-content: center;" class="adaptive-text">
								<v-card-text>
									<div class="flex-container adaptive-text">
										<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
											<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
										</svg>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;&nbsp;Company Name: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="selected_company.name" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-account-group</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;&nbsp;Name: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="selected_company.guest_name" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="40" left>mdi-chair-rolling</v-icon>
										<div class="align-start" style="font-size: 18px; margin-top: 10px;">&nbsp;Table Number: &nbsp;</div>
										<v-text-field style="font-size: 18px; margin-top: 27px;" class="adaptive-text orange-text-field" dense disabled v-model="selected_company.table_num" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-silverware-variant</v-icon>
										<span style="font-size: 17px;">Buffet Table:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="selected_company.buffet_table" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-ticket-confirmation</v-icon>
										<span style="font-size: 17px;">Ticket Count:</span>
										<v-text-field style="font-size: 17px;" class="adaptive-text orange-text-field" dense disabled v-model="selected_company.company_tickets.length" color="orange"></v-text-field>
									</div>
								</v-card-text>
								<v-card-text>
									<div class="flex-container adaptive-text">
										<v-icon size="35" left>mdi-silverware-variant</v-icon>
										<span style="font-size: 17px;">Tickets: </span>
										<span style="font-size: 17px; word-break: break-word; color:orange;" class="adaptive-text orange-text-field"  color="orange"> {{ selected_company.ticket_numbers.join(', ') }}</span>
									</div>
								</v-card-text>
							</div>
						</div>
						<v-divider :thickness="50"></v-divider>
					</div>  
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
    </v-main>
</template>

<script>
	import Swal from 'sweetalert2';
	export default {
	name: 'HelloWorld',
	data() {
		return {
			referenceNumber: '',
			showInfoPanel: -1,
			// employeeName: '',
			// company_name: '',
			// showEventInformation: false,
			// tableNumber: '',
			// mobileBarTicket: '',
			// buffetTable: '',
			remainingTickets: '',
			employeeDatas: [],
			Html5Qrcode: null,
			scannedqrcodes: '',
			qrScannerRunning: false, 
			dialog: false,
			tab: false,
			qrImage: '',
			uploadModalOpen: false,
			uploadFileModalOpen: false,
			selectedFile: null,
			selectedFileName: null,
			registered_participants: [],
			registered_names: null,
			hovered: false,
			settings: {
				data_grouping: null,
			},
			dropdowns:{
				company_list: []
			},
			company_id: null,
			companySearched: false,
			selected_company: {},
			search: '',
			loading: false,
			items: []
		};
	},
	beforeRouteLeave(to, from, next) {
    	next()
	},
	computed: {
		isTextFieldNotEmpty() {
			this.showInfoPanel = 0;
			this.tab = false;
		return !!this.selectedEmployeeName.trim();
		},
		selectedEmployee() {
			return this.employeeDatas.find(employee => employee.employee_id === this.referenceNumber) || 
			{ full_name: '', company_name: '', employee_id: '', table_num: '', mobile_bar_count: '', buffe_meal_id: '', };
		},
		selectedEmployeeName() {
			return this.selectedEmployee.full_name;
		},
		formattedEmployeeName() {
			return `${this.selectedEmployee.employee_id}`;
		},
		formattedCompanyName() {
			return `${this.dropdowns.company_list.find(x=> x.id == this.selectedEmployee.company_id).name}`;
		},
		formattedTable() {
			return `${this.selectedEmployee.table_num}`;
		},
		formattedBuffet() {
			return `${this.selectedEmployee.buffet_meal_id}`;
		},
		formattedMobileBar() {
			return `${this.selectedEmployee.mobile_bar_count}/2`;
		},
	},
	mounted(){
		this.getGroupingSettings();
		this.getCompanyDropdowns();
	},
	methods: {
		submitReferenceNumber(){
			this.submitRefID();
			// this.generateQR();
			this.tab = true;
		},
		async fetchData() {
			let payload = {
				url: "event-participants",
				id: this.data,
			};
			try {
				this.$store.dispatch("urlGet",  payload).then((res)=>{
				this.employeeDatas = res.data;
				this.registered_participants = this.employeeDatas.map(data => data.employee_id);
				})
				this.$store.commit('NEW_MASTER_DATA', true);
			} catch (error) {
				console.error(error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Failed to fetch data!',
				});
			}
		},
// async generateQR() { 
	
// 			try {
// 				let response = await axios.post(process.env.VUE_APP_API_HOST + '/api/generate_QR', {
// 					employee_id: this.referenceNumber
// 				});
// 				let svgDataUri = `data:image/svg+xml;base64,${btoa(response.data)}`;
// 		this.qrImage = svgDataUri;
// 		} catch (error) {
// 		console.error(error);
// 		}
// 		},
// async downloadQR() {
//   let canvas = document.createElement('canvas');
//   let ctx = canvas.getContext('2d');
//   let img = new Image();
//   img.onload = () => {
//     // Increase canvas dimensions for higher quality
//     let scaleFactor = 5; // Increase this value for higher quality
//     canvas.width = img.width * scaleFactor;
//     canvas.height = img.height * scaleFactor;
//     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//     let link = document.createElement('a');
//     link.href = canvas.toDataURL('image/png');
//     link.download = `${this.selectedEmployeeName}_QR_Code.png`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     Swal.fire({
//       title: 'CHECK YOUR DOWNLOADS!',
//       text: 'Your QR Code has been downloaded successfully!',
//       icon: 'success',
//       allowOutsideClick: false,
//       customClass: {
//         confirmButton: 'orange-button',
//       },
//     });
//   };
//   img.src = this.qrImage;
// },
		async submitRefID() {
			Swal.fire({
				allowOutsideClick: false,
				showCancelButton: false,
				showConfirmButton: false,
			});
			if (!this.referenceNumber) {
				Swal.fire({
					icon: 'error',
					title: 'Please Input',
					allowOutsideClick: false,
					text: `Please enter a ${ this.settings.data_grouping == 1 ? "reference number" : "company"}.`,
					customClass: {
					confirmButton: 'orange-button',
					}
				});
				return;
			}
			// if (this.referenceNumber.length < 9 || this.referenceNumber.length > 9) {
			// 	Swal.fire({
			// 		icon: 'error',
			// 		title: 'Invalid Reference Number',
			// 		allowOutsideClick: false,
			// 		text: 'Please check your Reference Number',
			// 		customClass: {
			// 		confirmButton: 'orange-button',
			// 		}
			// 	});
			// 	return;
			// }
		// localStorage.setItem('refNumberConfirmation', this.referenceNumber || this.scannedqrcodes);
			let payload = {
				url: "confirm-employee-registration",
				id: this.referenceNumber,
				data_grouping: this.settings.data_grouping
			}
			Swal.showLoading();
			// let response = await axios.post(process.env.VUE_APP_API_HOST + '/api/confirm-employee-registration', {
				await this.$store.dispatch("urlPost", payload).then((response)=>{

			// this.tableNumber = response.data["0"].table_num;
			// 		this.buffetTable = response.data["0"].buffet_meal_id;
			// 		this.mobileBarTicket = response.data["0"].mobile_bar_count;
			// 		this.employeeName = response.data["0"].full_name;
			// 		this.company_name = response.data["0"].company_name;
			// 		this.referenceNumber = response.data.employee_id;
			
				Swal.fire({
					title: response.message,
					html: ` Welcome! <br> ${response["0"].full_name}
							<br> of <br> ${this.dropdowns.company_list.find((x) => x.id == response[0].company_id).name}`,
					text: response.message, 
					icon: 'success',
					allowOutsideClick: false,
					customClass: {
						confirmButton: 'orange-button',
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.showInfoPanel = 0;
					}
				});
			});
		},
		toggleUploadModal() {
			this.uploadModalOpen = !this.uploadModalOpen;
		},
		reloadPage() {
			window.location.reload();
		},
		startCarouselAutoAdvance() {
			this.carouselTimer = setInterval(() => {
				this.currentItem = (this.currentItem + 1) % this.items.length;
			}, 10000); // Advance every 5 seconds (5000 milliseconds)
		},
		stopCarouselAutoAdvance() {
			clearInterval(this.carouselTimer);
		},
		reset() {
			this.currentItem = 1;
		},
		chooseFile() {
			this.$refs.fileInput.click();
		},
		handleFileChange(event) {
			let file = event.target.files[0];
			this.selectedFile = file;
			this.selectedFileName = file ? file.name : null;
		},
		clearFile() {
			this.selectedFile = null;
			this.selectedFileName = null;
		},
		clearReferenceNumber() {
			this.referenceNumber = '';
		},
		async getGroupingSettings(){
			let payload = {
                type: 'Expansions',
                url: 'moduleSettings'
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.settings).filter(el=>{
                        if(el == e.parameter){
                            this.settings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
		},
		getCompanyDropdowns(){
			let payload = {
				url: 'companyRoute'
			}
			this.$store.dispatch('urlGet', payload).then((res)=>{
				this.dropdowns.company_list = res.data
			});
		},
		filterGuests(){
			this.companySearched = true;
			this.selected_company = this.dropdowns.company_list.find((x)=> x.id == this.company_id);
			this.selected_company.ticket_numbers = this.selected_company.company_tickets.map(x => x.ticket_num)
		},
		querySelections (v) {
			this.loading = true
			let payload = {
				url: 'getEmployeeSearch',
				company_id: this.company_id,
				search: this.search
			}
			this.$store.dispatch('urlPost', payload).then((res)=>{
				this.items = res
				this.loading = false
			})
		},
	},
	created() {
		document.title = 'Attendee Confirmation';
		this.fetchData();
	},
	watch: {
		search (val) {
			val && val !== this.referenceNumber && this.querySelections(val)
		},
		referenceNumber (val){
			let guest_details = this.employeeDatas.find((x)=> x.employee_id == val);
			if(guest_details){
				this.selected_company.table_num = guest_details.table_num;
				this.selected_company.buffet_table = guest_details.buffet_meal_id;
				this.selected_company.guest_name = guest_details.full_name;
			}else{
				this.selected_company.table_num = '';
				this.selected_company.buffet_table = '';
				this.selected_company.guest_name = '';
			}
		}
    },
}
</script>

<style >
    @import '/src/assets/css/HelloWorld.css';
    .system_toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    }
    @media screen and (max-width: 1000px) {
    .system_toolbar {
        flex-direction: column;
        align-items: flex-start;
    }
    /* Add more specific styling for smaller screens */
    }
    .transparent-btn { background-color: transparent; border: none; }
    .black-font { color: black !important; }
    .slide-enter-active, .slide-leave-active {
        transition: transform 0.5s;
    }
    #ingco-logo-img{
        height: 40px;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-6%);
    }
    body {
        overflow: hidden;
    }
    .flex-container {
        display: flex;
        align-items: center;
    }
    .dotted-border-orange {
        border: 1px dotted rgb(255, 255, 255);
    }
    .flex-container span {
        margin-right: 10px; 
    }
    .adaptive-text {
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .adaptive-text {
        font-size: 12px; 
        }
    }
    .fillup-button-textsize{
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .fillup-button-textsize {
        font-size: 10px; 
        }
    }
    .small-header {
        padding-top: 1px; /* Adjust as needed */
        padding-bottom: 1px; /* Adjust as needed */
    }
    body {
    background-color: black;
    }
    .dotted-border-orange {
    border: 1px dotted rgb(255, 255, 255)!important;
    }
	.orange-text-field input {
    color: orange !important;
  }
</style>

