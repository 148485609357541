import repository from '@/api/modules/masterdata/masterdata'
import Swal from 'sweetalert2'
import router from '@/components/routes/routes';

export default {
    state: {
        new_master_data: false,
        dialog: false,
        action: '',
        dialog_view: {},
		form_settings:[],
        participants_list: [],
        file_viewer_dialog: false,
        file_viewer: [],
        new_data: false,
        event_id: null,
        font_family: [
            'Evogria',
            'Gilroy'
        ],
        user: [],
        gender: '',
    },
    getters: {
        GET_NEW_MASTER_DATA:(state) => state.new_master_data,
        DIALOGS:(state) => state.dialog,
        ACTION: state => state.action,
        GET_DIALOG_VIEW: (state) => state.dialog_view,
        GET_FORM_SETTINGS: (state) => state.form_settings,
        GET_PARTICIPANT_LIST: (state) => state.participants_list,
        GET_FILE_VIEWER_DIALOG: (state) => state.file_viewer_dialog,
        GET_FILE_VIEWER: (state) => state.file_viewer,
        GET_NEW_DATA: (state) => state.new_data,
        GET_EVENT_ID: (state) => state.event_id,
        GET_ALL:(state) => state,
        USERACCOUNT_LOAD: (state) => state.user,
        GET_GENDER: (state) => state.gender,
    },
    actions:{
        urlGet({commit}, payload){
            return new Promise((resolve, reject)=>{
                payload.event_id = this.getters.GET_EVENT_ID
                repository.urlGet(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlPost({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPost(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlPut({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPut(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlDelete({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlDelete(payload).then((response)=>{
                    commit('NEW_MASTER_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlShow({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlShow(payload).then((response)=>{
                    // commit('NEW_MASTER_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        credentials({ commit }) {
            return new Promise((resolve, reject) => {
              repository.useraccount().then(({ data, status }) => {
                    if (status == 200) {
                        commit('USERACCOUNT_LOAD', data)
                        resolve(data)
                    }
                }).catch((error)=>{
                    console.log(error.response)
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            });
        },
    },
    mutations:{
        NEW_MASTER_DATA:(state, data) => {state.new_master_data = data},
        DIALOG:(state, data) => {state.dialog = data},
        ACTION:(state, data) => {state.action = data},
        DIALOG_VIEW:(state, data) => {state.dialog_view = data},
        FORM_SETTINGS:(state, data) => {state.form_settings = data},
        PARTICIPANT_LIST:(state, data) => {state.participants_list = data},
        FILE_VIEWER_DIALOG:(state, data) => {state.file_viewer_dialog = data},
        FILE_VIEWER:(state, data) => {state.file_viewer = data},
        NEW_DATA:(state, data) => {state.new_data = data},
        EVENT_ID:(state, data) => {state.event_id = data},
        GENDER:(state, data) => {state.gender = data},
        USERACCOUNT_LOAD: (state, data) => {
            state.user = data;
        },
    }
}
