<template>
  <v-main>
    <v-container fluid>
      <v-row fluid class="system_toolbar d-flex w-auto justify-content-between">
        <v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
          <v-img
          max-height="50"
          max-width="190"
          src="@/assets/ingcoph_logo_alt.png"
          style="margin-left: -5px"
          class="logo-img"
        ></v-img>          <!-- <v-menu :absolute="false" offset-y class="black-font underline-animation-black" max-width="250" style="margin-left: 10px; font-family: Evogria; ">
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-show="showTab" v-bind="attrs" v-on="on" @click="toggleMenu" color="transparent" dark plain class="black-font underline-animation-black" style="font-family: Evogria;">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24">
                  <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2">
                    <path :d="clicked ? 'M5 5L19 5' : 'M5 5L19 19'" />
                    <path :d="clicked ? 'M5 12H19' : 'M12 12H12'" />
                    <path :d="clicked ? 'M5 19L19 19' : 'M5 19L19 5'" />
                  </g>
                </svg>
              </v-tab>
            </template>
            <v-list style="font-family: Evogria;" class="gray-border">
              <v-list-item>
                <v-chip small plain to="/MobileBar" @click="reloadPage" color=#ffa500>
                  <v-list-item-title style="font-size: 12px; color:black;">Mobile Bar</v-list-item-title>
                  <v-spacer> &ensp;</v-spacer>
                  <v-icon size="33" color="black">mdi-liquor</v-icon>
                </v-chip>
              </v-list-item>
              <v-list-item>
                <v-chip small plain to="/register" @click="reloadPage" color=#ffa500>
                  <v-list-item-title style="font-size: 12px; color:black;">pre-registration</v-list-item-title>
                  <v-spacer> &ensp;</v-spacer>
                  <v-icon size="33" color="black">mdi-fountain-pen-tip</v-icon>
                </v-chip>
              </v-list-item>
              <v-list-item>
                <v-chip small plain to="/confirmation" @click="reloadPage" color=#ffa500>
                  <v-list-item-title style="font-size: 12px; color:black;">confirm registration</v-list-item-title>
                  <v-spacer> &ensp;</v-spacer>
                  <v-icon size="33" color="black">mdi-check-all</v-icon>
                </v-chip>
              </v-list-item>
            </v-list>
          </v-menu> 
          <div v-if="isDesktop" style="display: flex;">
            <v-col cols="12">
              <v-btn x-small to="/MobileBar" @click="reloadPage" color="transparent" dark class="black-font underline-animation-black" style="margin-left: -10px; font-family: Evogria;">
                Mobile Bar
                <v-icon size="32" center>
                  mdi-liquor
                </v-icon>
              </v-btn>
            <v-btn x-small to="/register" @click="reloadPage"  color="transparent" dark class="black-font underline-animation-black" style="margin-left: 10px; font-family: Evogria;">
                pre-registration
                <v-icon size="32" center>
                  mdi-fountain-pen-tip
                </v-icon>
              </v-btn> -->
              <!-- <v-btn x-small to="/confirmation" @click="reloadPage"  color="transparent" dark class="black-font underline-animation-black" style="margin-left: 10px; font-family: Evogria;">
                confirm registration
                <v-icon size="32" center>
                  mdi-check-all
                </v-icon>
              </v-btn>
              <v-btn x-small to="/INGCOdashboard" @click="reloadPage, $emit('toggle-sheet')" color="transparent" dark class="black-font underline-animation-black" style="margin-left: 10px; font-family: Evogria;">
                Official Participants
                <v-icon size="32" center>
                  mdi-table-star
                </v-icon>
              </v-btn>
            </v-col>
          </div>-->
        </v-tabs>  
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      clicked: true,
      isDesktop: window.innerWidth > 1000,
      showTab: window.innerWidth <= 1000,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    redirectToLandingPage() {
      this.$router.push('/INGCONight');
      this.reloadPage();
  },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.toggleSVG();
    },
    toggleSheet() {
      this.$emit('toggle-sheet');
    },
    reloadPage() {
      window.location.reload();
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 1000;
      this.clicked = this.isDesktop;
      this.clicked = !this.clicked;
      this.showTab = window.innerWidth <= 1000;
    },
    toggleSVG() {
      this.clicked = !this.clicked;
    }
  },
};
</script>

<style>
.system_toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  .system_toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  /* Add more specific styling for smaller screens */
}
  .transparent-btn { background-color: transparent; border: none; }
  .black-font { color: black !important; }
  .slide-enter-active, .slide-leave-active {
    transition: transform 0.5s;
  }
  #ingco-logo-img{
    height: 40px;
  }
  .slide-enter, .slide-leave-to {
    transform: translateX(-6%);
  }
  body {
    overflow: hidden;
  }
</style>
