<template>
    <v-img src="@/assets/backgroundnominee.jpg" height="100vh" class="pa-0">
        <v-row v-for="(item, i) in types" :key="i" class="text-center">
            <v-col v-if="list[item].length > 5">
                <h1 class="pa-4 ml-3" >
                Official Nominee Vote For {{ item }}
                </h1>   
                <v-row class="container" >
                    <div v-for="(nominee, j) in list[item]" :key="j" cols="3" class="ma-0">
                        <v-img
                            src="@/assets/nomineeList.png"
                            height="300"
                        >
                            <v-img
                                :src="checkImagePath(nominee.main_image)"
                                class="align-end"
                                height="200px"
                                contain
                            />
                            <v-card-text class="text-center">
                                <h3>{{ nominee.full_name + ` (${nominee.vote_count})`}}</h3>
                                <h4>from {{ nominee.company_name }}</h4>
                            </v-card-text>
                        </v-img>
                    </div>
                </v-row>
            </v-col>
            <v-col v-else>
                <h1 class="pa-4 ml-3" >
                Official Nominees For {{ item }}
                </h1>   
                <v-row class="d-flex justify-content-center" >
                    <v-col v-for="(nominee, j) in list[item]" :key="j" cols="2" class="ma-2">
                        <v-img
                            src="@/assets/nomineeList.png"
                            height="300"
                        >
                            <v-img
                                :src="checkImagePath(nominee.main_image)"
                                class="align-end"
                                height="200px"
                                contain
                            />
                            <v-card-text class="text-center">
                                <h3>{{ nominee.full_name }}</h3>
                                <h4>from {{ nominee.company_name }}</h4>
                            </v-card-text>
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-img>
</template>

<script>
	import Swal from 'sweetalert2';
    import SharedFunctionsComponentVue from '@/components/shared/SharedFunctionsComponent.vue';
	export default {
    mixins: [SharedFunctionsComponentVue],
	data() {
		return {
			list:{
                Male: [],
                Female: [],
            },
            types: ['Male', 'Female']
		};
	},
	// beforeRouteLeave(to, from, next) {
    // 	next()
	// },
	computed: {
        infiniteList() {
            // Duplicate the list to create an infinite loop
            return [...this.types.map(type => ({ type, nominees: this.list[type] })), ...this.types.map(type => ({ type, nominees: this.list[type] }))];
        },
        scrollStyle() {
            return {
                animation: `scroll-left 10s linear infinite`
            };
        }
	},
	mounted(){
        this.getNomineeList();
	},
	methods: {
		getNomineeList(){
            this.types.forEach(element => {
                let payload = {
                    url: 'eventNominees',
                    voteable: 1,
                    gender: element
                }
                this.$store.dispatch('urlGet', payload).then((res)=>{
                    let count = 2;
                    // for(let i = 1; i <= count; i++ ){
                    //     this.list[element].push(...res.data)
                    // }
                    this.list[element] = res.data;
                })
            });
        }
	},
	created() {
	    document.title = 'Vote List';
	},
	watch: {
		
    },
}
</script>

<style>
    .bg_image{
        background-image: url('../../../assets/backgroundnominee.jpg');
        object-fit: cover;
        background-size: cover;
    }
    @import '/src/assets/css/HelloWorld.css';
    .system_toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @media screen and (max-width: 1000px) {
    .system_toolbar {
        flex-direction: column;
        align-items: flex-start;
    }
    /* Add more specific styling for smaller screens */
    }
    .transparent-btn { background-color: transparent; border: none; }
    .black-font { color: black !important; }
    .slide-enter-active, .slide-leave-active {
        transition: transform 0.5s;
    }
    #ingco-logo-img{
        height: 40px;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-6%);
    }
    body {
        overflow: hidden;
    }
    .flex-container {
        display: flex;
        align-items: center;
    }
    .dotted-border-orange {
        border: 1px dotted rgb(255, 255, 255);
    }
    .flex-container span {
        margin-right: 10px; 
    }
    .adaptive-text {
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .adaptive-text {
        font-size: 12px; 
        }
    }
    .fillup-button-textsize{
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .fillup-button-textsize {
        font-size: 10px; 
        }
    }
    .small-header {
        padding-top: 1px; /* Adjust as needed */
        padding-bottom: 1px; /* Adjust as needed */
    }
    body {
    background-color: black;
    }
    .dotted-border-orange {
    border: 1px dotted rgb(255, 255, 255)!important;
    }
	.orange-text-field input {
        color: orange !important;
    }

    h4 ,h1 , h5, h2, h3 {
		font-family: 'Evogria';
		color: #ffcd57;
	}
    section {
	display:flex;
	flex-flow:column;
	align-items:center;

	@media (max-width:992px) { padding:0 20px 0 20px; }
}

    section {
        display:flex;
        flex-flow:column;
        align-items:center;

        @media (max-width:992px) { padding:0 20px 0 20px; }
    }
	div.container {
		transition:all .3s ease;

	}
    	
    section.slide-option {
        margin:0 0 50px 0;
    }

    @keyframes translatestf {
        0% { transform:translateX(100%);  }
        100% { transform:translateX(-500%);  }
    }


    .scroll-container {
        overflow: hidden;
        position: relative;
    }

    .scroll-wrapper {
        display: flex;
        width: 200%; /* Double the width for seamless scrolling */
    }

    .scroll-content {
        display: flex;
        width: 50%; /* Half of the wrapper width */
        align-items: center;
    }

    @keyframes scroll-left {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%);
        }
    }

    .scroll-content > * {
        flex: 0 0 auto; /* Prevent flex items from shrinking */
    }
</style>

<style lang="scss" scoped>
    $n:6; /* number of elements */

    .container  {
        --d: 60s; /* duration */
        display: grid;
        grid-template-columns: repeat(6, 350px); /* number of visible elements + width */
        -webkit-mask: linear-gradient(90deg,#0000,#000 5% 95%,#0000);
        margin-left: 30px;
    }


    .container > div {
        grid-area: 1/1;
        grid-template-columns: 120px 1fr;
        align-content: center;
        gap: 10px;
        border-inline: 10px solid #0000;
        animation: r var(--d) linear infinite;
        
    }
 
    @for $i from 2 to ($n + 1) {
        .container > div:nth-child(#{$i}) {animation-delay: calc(#{(1 - $i)/$n}*var(--d))}
    }
    @keyframes r {
        #{100/$n}% {transform: translate(-100%)}
        #{100/$n + .01}% {transform: translate(($n - 1)*100%)}
    }

</style>