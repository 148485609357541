<template>
    <v-form ref="form" v-model="valid">
        <v-card class="overflow-x-hidden">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom">
                <span class="text-h5">Activity Details</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 mt-1" fluid>
                    <v-row no-gutters>
                        <v-col>
                            <v-btn
                                @click="triggerFileViewerDialog(true)"
                            >
                                <v-icon>
                                    mdi-folder-image
                                </v-icon>
                            </v-btn>
                            <BaseFilesViewerVue :event_activity_images="form.event_activity_images"/>
                            <FileUploadComponent ref="fileUpload" :label="'Event Images'" @uploadedData="uploadedData" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" class="pa-0 pb-2">
                            Event Activity Main Image
                            <v-hover v-model="isHover">
                                <template v-slot="{ hover }">
                                    <v-card style="border: #444444 2px solid;">
                                        <v-container fluid class="picture">
                                            <v-img :src="form.activity_image" contain aspect-ratio="1.7">
                                            </v-img>
                                        </v-container>
                                        <v-fade-transition style="opacity:0.5;">
                                            <v-overlay absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                                                    <v-row>
                                                        <v-tooltip bottom color="#2590ff">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    v-show="hover == true"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    :disabled="disabled_view"
                                                                    style="opacity:1; cursor: pointer;"
                                                                    class="ma-2"
                                                                    color="#2590ff"
                                                                    text
                                                                >
                                                                    <label for="upload-file"><span class="upload-file"><v-icon x-large>mdi-tray-arrow-up</v-icon></span></label>
                                                                </v-btn>
                                                            </template>
                                                            <span>Upload Image</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.name"
                                :disable="disabled_view"
                                label="Name"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-textarea
                                v-model="form.description"
                                :disable="disabled_view"
                                :rules="rules"
                                counter="200"
                                label="Description"
                                outlined
                                required
                                dense
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #000000"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addRafflePrize() : updateRafflePrize()"
                            :disabled="disabled_view || !checkForRules() ? true: false"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import FileUploadComponent from "@/components/shared/FileUploadComponent.vue"
import BaseFilesViewerVue from '@/components/shared/BaseFilesViewer.vue';
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
                max_words: [(v) => (v).length <= 25 || 'Max 25 characters']
            },
            form: {},
            isHover: false,
            rules: [
                value => !value || value.length <= 200|| 'Max 200 characters.',
            ],
            isMultiple: false,
        };
    },
    components:{
        FileUploadComponent,
        BaseFilesViewerVue
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_EVENT_ID"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getActivityData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getActivityData();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
    },
    methods: {
        initialForm() {
            this.form = {
                code        : '',
                name        : '',
                order       : '',
                activity_image : '',
                event_activity_images: [],
            };
            // this.$refs.fileInput.value = null;
            this.clearFileUpload();
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async addRafflePrize() {
            this.$refs.form.validate();

            let name_swal_text = this.form.name ? '' : 'name';
            let description_swal_text = this.form.description ? '' : 'description';
            let array_text =
            [
                name_swal_text,
                description_swal_text
            ]
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })

            if (
                this.form.name &&
                this.form.description
            ) {
                let payload = {
                    url: "eventActivities",
                    ...this.form,
                    event_id: this.GET_EVENT_ID	
                }
                await this.$store.dispatch('urlPost', payload).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit('NEW_MASTER_DATA', true);
                    this.$store.commit("DIALOG", false);
                })
            } else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }
        },
        async updateRafflePrize() {
            // if(this.form.event_activity_images.length > 0){
            //     for (let file of this.form.event_activity_images) {
            //         let base64String = await this.fileToBase64(file);
            //         console.log(base64String); // or do whatever you want with the base64 string
            //     }
            // }else{
            //     this.form.event_activity_images = this.fileToBase64(this.form.event_activity_images)
            // }
            let payload = {
                url: "eventActivities",
                id: this.form.id,
                ...this.form,
                event_id: this.GET_EVENT_ID	
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
				this.closeDialog();
			});
        },
        async getActivityData() {
            this.form.id                    = this.GET_DIALOG_VIEW.id;
            this.form.name                  = this.GET_DIALOG_VIEW.name;
            this.form.description           = this.GET_DIALOG_VIEW.description;
            this.form.activity_image        = this.GET_DIALOG_VIEW.activity_image;
            this.form.event_activity_images = this.GET_DIALOG_VIEW.event_activity_images;
        },
        uploadImage(event) {
            let that = this;
			let reader = new FileReader()
			reader.addEventListener('load', () => {
				that.form.activity_image = reader.result
			})
			let file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        uploadedData(data) {
            if (!data) {
                this.form.event_activity_images = null;
            }
            this.form.event_activity_images = data.attachments;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },
        triggerFileViewerDialog(value){
            if(value){
                this.$store.commit('FILE_VIEWER_DIALOG', value);
                this.$store.commit('FILE_VIEWER', this.form.event_activity_images)
            }
        },
        checkForRules(){
            if(this.$refs.form){
                this.$refs.form.validate()
                return this.valid
            }
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }
            },
        },
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
