<template>
    <v-card>
        <v-card-title>
        </v-card-title>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="form.raffle_duration"
                                label="Raffle Duration (seconds)"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="form.ticket_length"
                                label="Ticket Number Length"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: ['event_id'],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            tab: null,
            items:[],
            settings_form:[],
            job_title_selection :[],
            form:{
                raffle_duration: 0,
                ticket_length: 0
            },
            monthsArray: [],
        }
    },
    mounted(){
        this.getAllRaffleSettings();
        this.getFilters()
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_FORM_SETTINGS',
            'GET_EVENT_ID',
        ])
    },
    methods:{
        getAllRaffleSettings(filter = null){
            this.initialSettings();
            this.loaded = false;
            let payload = {
                type: 'Raffle',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID,
            };
            this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.form).filter(el=>{
                    
                        if(el == e.parameter){
                            this.form[el] = parseFloat(e.value)
                        }
                    });
                })
            })
        },
        getFilters(){
            let filters ={
                data: this.form,
                type:'Raffle'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
        initialSettings(){
            this.form = {
                raffle_duration: 0,
                ticket_length: 0
            }
        }
    },
    watch:{
        // GET_FORM_SETTINGS:{
        //     handler(val){
        //         this.items = val

        //         val.forEach(e=>{
        //             Object.keys(this.form).filter(el=>{
        //                 if(el == e.parameter){
        //                     this.form[el] = parseFloat(e.value)
        //                 }
        //             });
        //         })
        //     }
        // },
        form:{
            handler(val){
                this.getFilters()
            },
            deep:true
        },
        GET_EVENT_ID:{
            async handler(val){
                if(val){
                    await this.getAllRaffleSettings();
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
</style>
