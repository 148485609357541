<template>
    <span>
        <v-file-input
            v-model="images"
            @change="onChange"
            @click:clear="reset();$emit('reset', true);"
            :label="label"
            :multiple="true"
            color="#ffa500"
            ref="inputUploader"
            type="file"
            name="files"
        >
        </v-file-input>
    </span>
</template>

<script>
import swal from 'sweetalert2'
var _ = require("lodash");

export default {
    props:{
        hideInput: {
            default: true,
            type: Boolean,
        },
        label: {
            default: '',
            type: String,
        },
        obj_key: {
            default: '',
            type: String,
        },
        previewKey: {
            default: '',
            type: String,
        },
        showPreview: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            name: '',
            files: '',
            success: '',
            attachments: [],
            images: null,
            previewFile:{
                dialog: false
            }
        }
    },
    watch: {
        attachments() {
            if(this.attachments.length > 0) {
                this.attachments = [];
            }
        },
    },
    methods: {
        clearAttachments() {
            this.attachments = [];
            this.images = null;
        },
        onChange(e) {
            let selectedFiles = [];

            if(e) {
                selectedFiles = e;
            }

            let fileTypes = ['jpg','jpeg','png','csv','txt','xlx','xls','xlsx','pdf','zip'];


            if(selectedFiles.length == 0) {
                return false;
            } else {
                // for(let i=0;i<selectedFiles.length;i++) {
                //     console.log(this.base64Convert(selectedFiles[i]))
                //     this.attachments.push(this.base64Convert(selectedFiles[i]))
                // }
                this.attachments = this.base64Convert(selectedFiles);

                let fileSizeChecker = _.compact(this.attachments).every(x=> {
                    return x.size < 8192000;
                });

                let fileTypeChecker = _.compact(this.attachments).every(x=> {
                    let fileName = x.name;
                    let fileNameLength = fileName.split('.').length;
                    let ext = fileName.split('.')[fileNameLength - 1];
                    return fileTypes.includes(ext);
                });

                // Emit Data
                if(!fileSizeChecker) {
                    swal.fire("", 'Each File size not more than 8MB', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                        }
                    });

                    this.$emit('uploadedData', false);
                } else if(!fileTypeChecker) {
                    swal.fire("", 'Each File must be jpg, jpeg, png, csv, txt, xlx, xls, xlsx, pdf and zip type', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                        }
                    });

                    this.$emit('uploadedData', false);
                } else {
                    let config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    this.$emit('uploadedData', {
                        attachments: this.attachments,
                        config: config,
                        obj_key: this.obj_key,
                        previewKey: this.previewKey,
                    });
                }
            }
        },
        reset() {
            this.$refs.inputUploader.reset();
            this.clearAttachments();
        },
        base64Convert(files) {
            let base64Array = [];

            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();

                reader.addEventListener('load', () => {
                    base64Array.push(reader.result);
                });

                reader.readAsDataURL(files[i]);
            }
            return base64Array;
        },
    }
}
</script>

<style>

</style>
