<template>
  <v-img src="@/assets/backgroundnominee.jpg" height="100vh" class="pa-0">
    <v-responsive class="pa-4" :aspect-ratio="16/9">
			<v-row>
			<!-- <img src="@/assets/WinnersRaffle.png" class="winner-list-img"> -->
				<v-spacer/>
					<v-col cols="1">
						<!-- <img
							v-if="showImage"
							src="@/assets/WinnersRaffle.png"
							class="winner-list-img"
						/> -->
							<v-btn
								id="WinnerListButton"
								@click="fetchOfficialWinners(1)"
								x-small
								tonal
								dark
								color="orange"
							>
								<v-icon size="20" center color="black"> mdi-star</v-icon>
							</v-btn>
							<v-dialog
								v-model="seeWinners"
								max-width="1200"
								height="auto"
								overflow-y="auto"
							>
								<v-card style="font-family: Evogria; background-color: black">
									<v-card-title style="justify-content: center">
										<span style="color:#ffa500;">
											Official Raffle Draw Winners
										</span>
										<v-btn
										small
										outlined
										color="black"
										depressed
										dark
										@click="downloadExcel"
										id="downloadWinnersBtn"
										>
										<v-icon size="31" center>
											mdi-folder-download-outline
										</v-icon>
										</v-btn>
									</v-card-title>
								<v-divider :thickness="5" class="gray-border"></v-divider>
									<v-data-table
										:headers="headers"
										:items="winners"
										style="background-color: black"
										item-key="id"
										class="elevation-1"
									>
									</v-data-table>
								</v-card>
							</v-dialog>
					</v-col>
			</v-row>
			<v-form class="mt-5">
				<v-row>
					<v-col cols="5">
						<div class="scene perspective-on">
							<div id="lottery" class="center">
								<div class="lottery-machine">
								<div
									class="lottery-lever"
									:class="{ down: leverPulled }"
								></div>
								<div
									class="lottery-right-door"
									:class="{ animation: isrightDoor }"
								></div>
								<div
									class="lottery-left-door"
									:class="{ animation: isleftDoor }"
								></div>
								<div class="lottery-lift" :class="{ animation: lift }"></div>
								</div>
								<div id="ball-place"></div>
							</div>
						</div>
						<v-btn
							@click="pullLeverAndShowImage"
							:disabled="buttonClicked || !raffleStarted ? true : false"
							class="orange-border underline-animation-black black-font glass-animation"
							id="play"
							style="font-family: Evogria"
							color="orange"
							dark
							solo
						>
						Draw
						</v-btn>
					</v-col>
					<v-col v-if="rafflePrizes.length > 0 && raffleStarted">
						<v-row>
							<v-col cols="12" class="text-center">
								<h1 style="font-size:60px;">{{ rafflePrizes[currentPrizeIndex].name}}</h1>
							</v-col>
							<v-col cols="12" class="d-flex justify-content-center">
								<v-img :src="rafflePrizes[currentPrizeIndex].prize_image" contain height="500" width="500"></v-img>
							</v-col>
						</v-row>
						<v-row class="pt-5">
						<!-- <v-row class="pa-0" v-if="winnerList.length > 0"> -->
							<v-col cols="12" class="text-center">
								<v-btn
									@click="navigatePrize('next')"
									:disabled="!hasDrawn || raffleEnded ? true: false"
									color="#ffa500"
									class="black--text"
									style="font-family: Evogria;"
								>
									NEXT PRIZE
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
					<!-- <audio id="lottoSound" src="@/assets/sound_effects/lottery_sound.mp3"></audio> -->
					<v-col v-if="!raffleStarted">
						<v-btn
							@click="startRaffle()"
							id="start"
							class="raffle-button black--text"
						>
							<span class="mt-1 d-flex justify-content-center align-items-center">
								<span style="font-family: Evogria;">
									Start
								</span>
								<v-icon>mdi-ticket</v-icon>
							</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<DialogComponent :cp="components" :hide_overlay="false" :scrollable="true" :width="height('50%')" :retain-focus="false"></DialogComponent>
    </v-responsive>
  </v-img>
</template>

<script>
import $ from "jquery";
import { gsap, Power2 } from "gsap";
import Swal from "sweetalert2";
import { schemeCategory20c } from "d3-scale-chromatic";
import confetti from "canvas-confetti";
import * as XLSX from "xlsx";
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from './shared/SharedFunctionsComponent.vue';
import DialogComponent from "@/components/layouts/dialogs/Dialog.vue"
import RaffleDrawComponentViewVue from '@/components/layouts/dialogs/raffleDraw/RaffleDrawComponentView.vue';

export default {
	mixins: [SharedFunctionsComponentVue],
	created() {
		document.title = "Raffle Draw";
	},
	components:{
		DialogComponent,
		RaffleDrawComponentViewVue
	},
	data() {
		return {
			playing: false,
			showImage: false,
			leverPulled: false,
			singleSelect: false,
			isleftDoor: false,
			buttonClicked: false,
			isrightDoor: false,
			ballSelected: false,
			ballRoll: false,
			lift: false,
			winners: [],
			retreivedOfficialWinners: [],
			indexWatcher: [],
			ballSelectedKey: 0,
			participants: [],
			rafflePrizes: [],
			currentPrizeIndex: 0,
			winnerList: [],
			raffleParticipants: [],
			colors: () => schemeCategory20c,
			lastId: 1,
			headers: [
				{
					text: "Draw Order",
					value: "order",
				},
				{
					text: "Draw Winner",
					value: "full_name",
				},
				{
					text: "prize",
					value: "prize",
				},
				{
					text: "time won",
					value: "time",
				},
			],
			employeeDatas: [],
			raffleSettings: {
				raffle_duration: 0,
				data_grouping: null
			},
			components: '',
			raffleStarted: false,
			hasDrawn: false,
			raffleEnded: false,
			seeWinners: false,
		};
	},
	methods: {
		async fetchData(refresh_prize = 0) {
		let payload = {
			url: "raffle-participants",
		};
		await this.$store.dispatch("urlGet", payload).then((res) => {
				if(refresh_prize == 0){
					this.rafflePrizes = res.rafflePrizes;
				}
				this.participants = res.raffleParticipants;
				this.$store.commit('PARTICIPANT_LIST', res.raffleParticipants)
				if ((res.raffleParticipants.length == 0) || (res.official_winners.length == res.total_prizes_length && !this.DIALOGS) ) {
					this.buttonClicked = true;
					this.raffleEnded = true;
					Swal.fire({
						title: "Raffle Draw Ended!",
						text: "Congratulations Winners!",
						icon: "success",
						allowOutsideClick: false,
						timer: 3000,
						customClass: {
							confirmButton: "orange-button",
						},
						showCancelButton: false,
  						showConfirmButton: false
					}).then((result) => {
						if (result.isConfirmed) {
							this.fetchOfficialWinners();
							// this.$refs.seeWinners.isActive = true;
						}else{
							this.fetchOfficialWinners();
						}
					});
					this.confetti();
				}
			}).catch((err) => {
				console.log(err);
				Swal.fire("Error", "", "error");
			});
		},
		pullLever() {
			this.leverPulled = true;
			this.$nextTick(() => {
				setTimeout(() => {
				this.leverPulled = false;
				// this.currentPrizeIndex++;
				this.lift = true;
				this.$nextTick(() => {
						setTimeout(() => {
							this.lift = false;
							this.isleftDoor = true;
							this.isrightDoor = true;
						}, 1000);
						setTimeout(() => {
							this.isleftDoor = false;
							this.isrightDoor = false;
						}, 3000);
					});
				}, this.raffleSettings.raffle_duration * 1000);
				this.isleftDoor = false;
				this.isrightDoor = false;
				this.ballRoll = false;
			});
		},
		//     lotteryAudio() {
		//     let audio = new Audio(require("@/assets/sound_effects/lottery_sound.mp3"));
		//     audio.onerror = (error) => {
		//         console.error('Audio playback error:', error);
		//     };
		//     audio.play();
		//      setTimeout(() => {
		//     audio.pause();
		//     audio.currentTime = 0; // Reset playback position to start
		// }, 10000);
		// },
		pullLeverAndShowImage() {
		// this.lotteryAudio();
			this.pullLever();
			this.showImage = true;

			// if (this.GET_PARTICIPANT_LIST.length == 0 || this.rafflePrizes.length == 0) {
			// // if (this.indexWatcher >= this.rafflePrizes.length - 2) {
			// 	this.buttonClicked = true;
			// 	setTimeout(() => {
			// 		Swal.fire({
			// 			title: "Raffle Draw Ended!",
			// 			text: "Congratulations Winners!",
			// 			icon: "success",
			// 			allowOutsideClick: false,
			// 			confirmButtonText: "See Winners",
			// 			customClass: {
			// 				confirmButton: "orange-button",
			// 			},
			// 		}).then((result) => {
			// 			if (result.isConfirmed) {
			// 				this.fetchOfficialWinners();
			// 				// this.$refs.seeWinners.isActive = true;
			// 			}
			// 		});
			// 	  this.confetti();
			// 	}, 19500);
			// } else {
			this.buttonClicked = true;
			this.hasDrawn = false;
			setTimeout(() => {
				this.hasDrawn = true;
			}, 5000);
				// setTimeout(() => {
				// // this.buttonClicked = false;
				// }, 19500);
			// }
		},
		confetti() {
		let duration = 10 * 1000;
		let end = Date.now() + duration;
		(function frame() {
			confetti({
				particleCount: 10,
				angle: 60,
				spread: 55,
				origin: {
					x: 0,
					y: 0.6,
				},
				colors: ["#bb0000", "#fef1aa"],

			});
			confetti({
				particleCount: 10,
				angle: 120,
				spread: 55,
				origin: {
					x: 1,
					y: 0.6,
				},
				colors: ["#bb0000", "#fef1aa"],
			});
			if (Date.now() < end) {
				requestAnimationFrame(frame);
			}
		})();
		},
		async fetchOfficialWinners(show_dialog = 0) {
			try {
				let payload = {
					url: "retreived-official-winners",
				};
				await this.$store.dispatch("urlGet", payload).then((response) => {
				this.retreivedOfficialWinners = response.winners;
				// console.log("retrieved", this.retreivedOfficialWinners);
				this.winners = response.winners
				if(show_dialog == 1){
					this.seeWinners = true;
				}
				});
			} catch (error) {
				console.error(error);
			}
			if (this.raffleEnded) {
				this.buttonClicked = true;
				this.seeWinners = true;
				this.confetti();
			}
		},
		downloadExcel() {
			let winnersData = this.winners.map((winner) => ({
				"Draw Order": winner.id,
				"Draw Winner": winner.full_name,
				Prize: winner.prize,
				Timestamp: winner.time,
			}));
			let wb = XLSX.utils.book_new();
			let ws = XLSX.utils.json_to_sheet(winnersData);
			XLSX.utils.book_append_sheet(wb, ws, "Winners");
			let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			let blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "INGCONIGHT-Raffle-Draw-Winners.xlsx");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			Swal.fire({
				title: "Check your Downloads!",
				text: "Raffle Winners Downloaded Successfully!",
				icon: "success",
				allowOutsideClick: false,
				customClass: {
					confirmButton: "orange-button",
				},
			});
		},
		async getRaffleDurationSettings(){
			let payload = {
                type: 'Raffle',
                url: 'moduleSettings'
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.raffleSettings).filter(el=>{
                        if(el == e.parameter){
                            this.raffleSettings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
		},
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
		startRaffle(){
			this.raffleStarted = true
		},
		navigatePrize(action){
			if(action == 'next'){
				this.currentPrizeIndex++
				this.buttonClicked = false
				this.hasDrawn = false
			}else{
				this.currentPrizeIndex--
			}
		},
		async getExpansionSettings(){
			let payload = {
                type: 'Expansions',
                url: 'moduleSettings'
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.raffleSettings).filter(el=>{
                        if(el == e.parameter){
                            this.raffleSettings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
		},
		handleKeyPressRaffle(event) {
			if(isNaN(event.key)){
				let keyPressed = event.key;
				switch(keyPressed){
					case 'ArrowRight':
						if(this.hasDrawn){
							this.navigatePrize('next');
						}
						break;
					case 'Escape':
						this.$store.commit('DIALOG', false)
						break;
					default:

					break;
				}
			}
        }
	},
	computed:{
		...mapGetters(['GET_PARTICIPANT_LIST', 'GET_EVENT_ID', 'DIALOGS'])
	},
	async mounted() {
		this.$store.commit('EVENT_ID', atob(this.$route.params.id))
		this.components = RaffleDrawComponentViewVue
		await this.getRaffleDurationSettings();
		await this.getExpansionSettings();
		window.addEventListener('keyup', this.handleKeyPressRaffle);

		let that = this;
		let ball_count = 0;
		let marqueeStyle = document.createElement("style");
		marqueeStyle.type = "text/css";
		document.head.appendChild(marqueeStyle);
		Promise.all([await this.fetchData(),this.fetchOfficialWinners() ]).then(() => {
		class LotteryMachine {
			constructor(
				participants,
				rafflePrizes,
				winnerList,
				employeeDatas,
				winners,
				retreivedOfficialWinners,
				opt = {}
			) {
			that.employeeDatas = winners;
			that.rafflePrizes = rafflePrizes;
			// that.winnerList = winnerList;
			that.participants = participants;
			let _def;
			_def = {
				class: "lottery-machine",
				viewClasses: {
					base: "base",
					lever: "lever",
					tube: "tube",
					rightDoor: "right-door",
					leftDoor: "left-door",
					lift: "lift",
					ball: "ball",
					hoverArea: "hover-area",
				},
				size: 645,
				ball: {
				// count: 2000,
					count: 3000,
					size: 35,
					colors: [
						"#c15bb6",
						"#8380c3",
						"#ffde7f",
						"#ff8f03",
						"#ec5767",
						"#ff8085",
					],
				},
				duration: [500, 525],
			};
			// _ASSERT(opt.ball.count?, "opt.ball.count is required")
			this.opt = $.extend(true, _def, opt);
			this.view = null;
			this.views = {};
			this.dom = { canvas: null };
			this.balls = [];
			this.build();
			}
			build(opt = {}) {
			let base, ref;
			base = this.dom;
			this.opt = $.extend(this.opt, opt);
			if (this.view == null) {
				this.view = $("<div>", { class: `${this.opt.class}` });
			}
			for (let key in this.opt.viewClasses) {
				this.views[key] = $("<div>", {
				class: `lottery-${this.opt.viewClasses[key]}`,
				});
				this.views[key].appendTo(this.view);
			}
			if (base.canvas == null) {
				base.canvas = $("<canvas>", {
				class: `${this.opt.class}-canvas`,
				}).appendTo(this.view);
			}
			// Add New Balls
			this.balls = function () {
				let results = [];
				for (
				let j = 0, ref = this.opt.ball.count;
					0 <= ref ? j < ref : j > ref;
					0 <= ref ? j++ : j--
				) {
				results.push(j);
				}
				return results;
			}
			.apply(this)
			.map((i) => {
				return this.createBall();
			});
			this._setContext();
				return this;
			}
			// Form a new ball
			createBall() {
			let _from, _ball;
			_ball = {
				// Initial Animation Options
				from: {
					pos: (_from = this.getBallPos(false)),
					time: null,
				},
				// Final Animation Options
				to: {
					pos: this.getRandomPos(),
				},
				pos: _from,
				// Animation duration
				duration: this.getRandomDuration(),
				rotate: 0,
			};
			return _ball;
			}
			// Remember context for molecules
			_setContext() {
			let _ratio, _size;
			_ratio = this._getRatio();
			_size = this.opt.size;
			this.dom.canvas
				.attr({
					width: _size * _ratio,
					height: _size * _ratio,
				})
				.css({
					width: _size,
					height: _size,
				});
				this.ctx = this.dom.canvas[0].getContext("2d");
				this.ctx.scale(_ratio, _ratio);
				this.ctx.save();
			return this;
			}
			// Get canvas scaling factor
			_getRatio() {
			return window.devicePixelRatio === 2 ? 2 : 1;
			}
			// Generate ball position
			getRandomPos() {
			let _angle, _m_radius, _pos, _radius, _t_radius, x, y;
			_angle = Math.random() * 2 * Math.PI;
			_radius = this.opt.size / 2;
			// Radius balls
			_m_radius = this.opt.ball.size / 2;
			// Circle radius for calculating coordinates
			// The radius of the balls is increased by 1/5 to eliminate gaps between the molecule and the walls of the test tube
			_t_radius = _radius - _m_radius + 0.5 * _m_radius;
			// Координаты на окружности
			y = Math.sin(_angle) * _t_radius;
			x = Math.cos(_angle) * _t_radius;
			_pos = {
				left: _radius + x,
				top: _radius - y,
			};
			return _pos;
			}
			// Generate ball position
			getBallPos(random = true) {
			let _angle, _m_radius, _pos, _radius, _t_radius, x, y, randomShift;
			randomShift = 0;
			if (random) {
				_angle = Math.random() * 2 * Math.PI;
			} else {
				_angle = (4 / 3) * Math.PI + (Math.random() * Math.PI) / 3;
				randomShift = Math.random() * 10 - 5;
			}
			_radius = this.opt.size / 2;
			// Radius Balls
			_m_radius = this.opt.ball.size / 2;
			// Circle radius for calculating coordinates
			// Increased by 1/5 of the ball radius to eliminate gaps between the molecule and the walls of the test tube
			_t_radius = _radius - _m_radius + 0.5 * _m_radius;
			// Coordinates on the circle
			y = Math.sin(_angle) * _t_radius + randomShift;
			x = Math.cos(_angle) * _t_radius + randomShift;
			_pos = {
				left: _radius + x,
				top: _radius - y,
			};
			return _pos;
			}
			// Generate ball position
			getRandomDuration() {
			return Math.round(
				Math.random() * [this.opt.duration[1] - this.opt.duration[0]] +
				this.opt.duration[0]
			);
			}
			// Generate rotation angle
			getRandomAngle() {
			return Math.round(Math.random() * 4 * Math.PI - 2 * Math.PI);
			}
			// Draw one molecule
			_drawBall(ball, index) {
			let _pos, _radius;
			this.ctx.save();
			// Позиция центра шарики
			_pos = {
				left: parseInt(ball.pos.left, 10),
				top: parseInt(ball.pos.top, 10),
			};
			// Радиус шарики
			_radius = this.opt.ball.size / 2;
			// Изменить начало координат
			this.ctx.translate(_pos.left, _pos.top);
			// Начальный угол поворота
			this.ctx.rotate(ball.rotate);
			this.ctx.moveTo(0, 0);
			// Внешние окружности
			this.ctx.beginPath();
			this.ctx.fillStyle =
				this.opt.ball.colors[Math.floor(Math.random() * 7)];
			this.ctx.arc(0, 0, _radius / 2, 0, 2 * Math.PI);
			this.ctx.fill();
			// Внутренние окружности
			this.ctx.beginPath();
			this.ctx.fillStyle = "#ffffff";
			this.ctx.arc(_radius / 6, -_radius / 6, _radius / 8, 0, 2 * Math.PI);
			this.ctx.fill();
			this.ctx.restore();
			return this;
			}
			// Нарисовать шарики
			drawBalls() {
			if (!this.disabled) {
				this._clearBalls();
				this.balls.map((ball, index) => {
				return this._drawBall(ball, index);
				});
			}
			return this;
			}
			// Произвольная анимация
			animate(opt = {}, step, cb) {
			let _deferred, _t_func, _time_from, def;
			// Значения по умолчанию
			def = {
				from: 0,
				to: 1,
				duration: 1000,
				step: 50,
			};
			opt = $.extend(true, def, opt);
			_deferred = $.Deferred();
			if (!this.disabled) {
				_time_from = Date.now();
				// Auxiliary recursive function
				_t_func = function () {
				let _k, _val;
				// Animation progress
				_k = (Date.now() - _time_from) / opt.duration;
				if (_k < 1) {
					_val = opt.from + _k * (opt.to - opt.from);
					step(_val);
					return setTimeout(_t_func, opt.step);
					// $.delay(opt.step, _t_func)
				} else {
					step(opt.to);
					_deferred.resolve();
					return typeof cb === "function" ? cb() : void 0;
				}
				};
				_t_func();
			} else {
				_deferred.resolve();
			}
			return _deferred;
			}
			// animation of mixing balls
			_shakeBalls(now) {
			this.views.lever.removeClass("hover").addClass("down");
			this.balls.forEach((ball) => {
				//console.log(now);
				// If the animation loop has ended or has not started
				var _k, i, len, ref, type;
				if (
					ball.from.time == null ||
					ball.from.time + ball.duration < now
				) {
					ball.from.time = now;
					ball.from.pos = $.extend({}, ball.pos);
					ball.to.pos = this.getRandomPos();
					ball.to.rotate = this.getRandomAngle();
				} else {
				// Recalculate coordinates
				_k = (now - ball.from.time) / ball.duration;
				ref = ["left", "top"];
				for (i = 0, len = ref.length; i < len; i++) {
					type = ref[i];
					ball.pos[type] =
					ball.from.pos[type] +
					_k * (ball.to.pos[type] - ball.from.pos[type]);
					ball.rotate = _k * ball.to.rotate;
				}
				}
			});
			this.drawBalls();
			}
			// аanimation of stopping mixing balls
			_endShakeBalls(now) {
			let winnerSound = document.getElementById("winnerSound");
			// setTimeout(function () {
			//     winnerSound.play();
			// }, 1000);
			this.views.lever.removeClass("down");
			this.balls.forEach((ball) => {
				// If the animation loop has ended or has not started
				var _k, i, len, ref, type;
				if (
				ball.from.time == null ||
				ball.from.time + ball.duration < now
				) {
				ball.from.time = now;
				ball.from.pos = $.extend({}, ball.pos);
				ball.to.pos = this.getBallPos(false);
				ball.to.rotate = this.getRandomAngle();
				} else {
				// Recalculate coordinates
				_k = (now - ball.from.time) / ball.duration;
				ref = ["left", "top"];
					for (i = 0, len = ref.length; i < len; i++) {
						type = ref[i];
						ball.pos[type] =
						ball.from.pos[type] +
						_k * (ball.to.pos[type] - ball.from.pos[type]);
						ball.rotate = _k * ball.to.rotate;
					}
				}
			});
			this.drawBalls();
			}
			// play: shakeBalls + endShakeBalls
			play() {
			let _now = Date.now();
			return this.animate(
				{
				from: _now,
				// to: _now + 3000,
				// duration: 3000,
				to: _now + that.raffleSettings.raffle_duration * 1000,
				duration: that.raffleSettings.raffle_duration * 1000,
				step: 2,
				},
				(now) => this._shakeBalls(now)
			).then(() => {
				_now = Date.now();
				this.animate(
				{
					from: _now,
					to: _now + 1000,
					duration: 1000,
					step: 2,
				},
				(now) => this._endShakeBalls(now)
				);
			});
			}
			// animation of rolling ball out of machine
			rollBallOut(
				parent,
				placeForBall,
				animationOpt,
				callback,
				raffleWinner,
				rafflePrizes,
				currentPrizeIndex,
				winnerList,
				employeeDatas
			) {
			// console.log("qwe", currentPrizeIndex);
			let { ball, lift, rightDoor, leftDoor } = this.views;
			let randomColor =
				this.opt.ball.colors[
				Math.floor(Math.random() * this.opt.ball.colors.length)
				];
			let randomNumber = [Math.floor(Math.random() * 100)];
			if (ball_count == 27) {
				$(".clone, .half-ball, .paper-in-between").remove(); // Remove generated elements
				ball_count = 0;
			}
			ball
				.removeClass("no-transition")
				.attr("style", "")
				.html("")
				.css({ backgroundColor: randomColor })
				.addClass("blink");
			function liftBallUp() {
				return new Promise((resolve) => {
				ball.addClass("animation-up");
				lift.addClass("animation");
				setTimeout(resolve, 1000);
				});
			}
			function liftBallDown() {
				return new Promise((resolve) => {
				ball.addClass("animation-roll");
				lift.addClass("animation");
				setTimeout(resolve, 1500);
				});
			}
			function openDoors() {
				return new Promise((resolve) => {
				ball
					.css({ zIndex: 2 })
					// .html(raffleWinner)
					.addClass("no-transition")
					.removeClass("blink animation-roll animation-up");
				rightDoor.addClass("animation");
				leftDoor.addClass("animation");
				setTimeout(resolve, 1000);
				});
			}
			function moveBallOut() {
				let startCoords = ball.offset();
				let start = {
					top: 650,
					left: 325,
				};
				let endCoords = placeForBall.offset();
				let spacingInterval = 7; // Adjust spacing every 5 balls
				let spacingOffset = 320; // Initial spacing offset
				let leftSpacing =
				endCoords.left +
				Math.floor(ball_count / spacingInterval) * spacingOffset +
				-400;
				// Calculate the resetTop based on leftSpacing adjustment
				let resetTop = Math.floor(ball_count / spacingInterval) * spacingInterval * 100;
				let nextBallPosition = {
					top: endCoords.top + ball_count * 100 - resetTop,
					left: leftSpacing,
				};
				let animatedBall = ball
				.clone()
				.addClass("clone")
				.css(start)
				.appendTo(parent);
					ball.css({ display: "none" });
				setTimeout(() => {
					ball.css({ display: "block" });
				}, 2000);
				ball_count++;
				return new Promise((resolve) => {
				gsap.to(animatedBall, 1, {
					...animationOpt,
					x: -(start.left - nextBallPosition.left),
					y: -(start.top - nextBallPosition.top) - 100,
					ease: Power2.easeInOut,
					parseTransform: true,
					onStart: () => {},
					onComplete: () => {
					// Create two halves of the ball
					// let halfWidth = animatedBall.width() / 2;
					// let raffleWinnerText = raffleWinner.full_name;
					// let textWidth = $("<span>")
					// 	.text(raffleWinnerText)
					// 	.css({
					// 	"font-family": "Evogria",
					// 	"font-size": "16px",
					// 	visibility: "hidden", // Hide the element from view
					// 	"white-space": "nowrap", // Prevent text wrapping
					// 	display: "inline-block",
					// 	})
					// 	.appendTo("body")
					// 	.width(); // Append to body to calculate the width
					// // Adding some padding to the calculated width for better visual appearance
					// let paperWidth = textWidth + 20; // Adjust padding as needed
					// // Create the first half
					// let firstHalf = $("<div>")
					// 	.addClass("half-ball")
					// 	.css({
					// 		width: halfWidth,
					// 		height: animatedBall.height(),
					// 		overflow: "hidden",
					// 		position: "absolute",
					// 		top: animatedBall.position().top,
					// 		left: animatedBall.position().left,
					// 		backgroundColor: animatedBall.css("background-color"), // Match original ball color
					// 		zIndex: 100,
					// 	})
					// 	.appendTo(parent);
					// // Create the second half
					// let secondHalf = $("<div>")
					// 	.addClass("half-ball")
					// 	.css({
					// 		width: halfWidth,
					// 		height: animatedBall.height(),
					// 		overflow: "hidden",
					// 		position: "absolute",
					// 		top: animatedBall.position().top,
					// 		left: animatedBall.position().left + halfWidth,
					// 		backgroundColor: animatedBall.css("background-color"), // Match original ball color
					// 		zIndex: 100,
					// 	})
					// 	.appendTo(parent);
					// // Set the border-radius to create half circles
					// firstHalf.css({
					// 	"border-top-left-radius": "50%",
					// 	"border-bottom-left-radius": "50%",
					// });
					// secondHalf.css({
					// 	"border-top-right-radius": "50%",
					// 	"border-bottom-right-radius": "50%",
					// 	"margin-left": 10 + textWidth + "px",
					// });
					// // Calculate the space between halves based on raffleWinner width
					// let raffleWidth = $(
					// 	`<span>${raffleWinner.full_name}</span>`
					// ).width();
					// let spaceBetween = (halfWidth - raffleWidth) / 2;

					// // Adjust positions for spacing based on raffleWinner width
					// firstHalf.css("padding-right", spaceBetween);
					// secondHalf.css("padding-left", spaceBetween);

					// let paperHeight = 48; // Set the desired height for the paper
					// let textMarginTop = (paperHeight - 16) / 2; // Assuming font-size is 16px

					// // Create a div for the paper effect
					// let paperInBetween = $("<div>")
					// 	.addClass("paper-in-between")
					// 	.css({
					// 	width: 10 + textWidth + "px", // Adjust the width as needed for the paper strip
					// 	height: paperHeight + "px", // Set the height of the paper
					// 	position: "absolute",
					// 	top:
					// 		animatedBall.position().top +
					// 		(animatedBall.height() - paperHeight) / 2, // Center vertically between the halves
					// 	left: animatedBall.position().left + halfWidth - 1, // Position the paper in between the halves
					// 	backgroundColor: "lightgray", // Set the color resembling paper texture or pattern
					// 	zIndex: 99, // Set the zIndex lower than halves to appear between them
					// 	display: "flex",
					// 	justifyContent: "center",
					// 	alignItems: "center",
					// 	})
					// 	.appendTo(parent);

					// // Create a div to hold the raffleWinner text
					// let raffleText = $("<div>")
					// 	.addClass("raffle-text")
					// 	.css({
					// 	color: "black", // Set text color
					// 	fontFamily: "Evogria", // Set font family
					// 	fontSize: "16px", // Set font size
					// 	marginTop: "5px", // Center text vertically
					// 	})
					// 	.text(raffleWinner.full_name)
					// 	.appendTo(paperInBetween); // Append text inside the paper

					let duration = 10 * 1000,
						animationEnd = Date.now() + duration,
						defaults = {
						startVelocity: 20,
						spread: 360,
						ticks: 90,
               	 		zIndex: 9999,
					};

					function randomInRange(min, max) {
						return Math.random() * (max - min) + min;
					}
					if(rafflePrizes[currentPrizeIndex].suspense_animate == 0){
						let interval = setInterval(function () {
							let timeLeft = animationEnd - Date.now();
	
							if (timeLeft <= 0) {
								return clearInterval(interval);
							}
	
							let particleCount =
							rafflePrizes[currentPrizeIndex].code != "Grand Prize"
								? 50 * (timeLeft / duration)
								: 80 * (timeLeft / duration);
	
							// since particles fall down, start a bit higher than random
							confetti(
								Object.assign({}, defaults, {
									particleCount: 200,
									colors: ["#bb0000", "#fef1aa"],
									origin: {
										x: randomInRange(0.1, 0.3),
										y: Math.random() - 0.2,
									},
								})
							);
							confetti(
								Object.assign({}, defaults, {
									particleCount: 200,
									colors: ["#bb0000", "#fef1aa"],
									origin: {
										x: randomInRange(0.7, 0.9),
										y: Math.random() - 0.2,
									},
								})
							);
						}, 250);
					}
					var refNumber = that.raffleSettings.data_grouping == 1 ? raffleWinner.employee_id : raffleWinner.ticket_num;
					var selectedName = that.raffleSettings.data_grouping == 1 ? raffleWinner.full_name : raffleWinner.company_ticket_name;
					var idRanking = raffleWinner.value;
					var employeedataID = raffleWinner.value;
					var rafflewinnerprizeId = raffleWinner.prize_id;
					var ticket_num = raffleWinner.full_name;
					let company = that.raffleSettings.data_grouping == 1 ? raffleWinner.participant_company_name : raffleWinner.company_ticket_name;
					let prizeInfo = {};
					if (rafflePrizes[currentPrizeIndex].winner_count != 0) {
						prizeInfo.name = rafflePrizes[currentPrizeIndex].name;
					}
					// console.log("qwe", currentPrizeIndex);

					// Swal.fire({
					// 	title: "Congratulations!",
					// 	html: `${selectedName}<br> Of <br>${company}`,
					// 	// icon: "success",
					// 	timer: 3800,
					// 	showCancelButton: false,
					// 	showConfirmButton: false,
					// 	backdrop: false,
					// 	showClass: {
					// 		popup: "animate__animated animate__fadeInDown",
					// 	},
					// 	hideClass: {
					// 		popup: "animate__animated animate__fadeOutUp",
					// 	},
					// });
					
					let payloadSave = {
						url: 'save-winner',
						prize_id: rafflePrizes[currentPrizeIndex].id,
						winnerID: that.raffleSettings.data_grouping == 1 ? raffleWinner.value : raffleWinner.ticket_num,
						data_grouping: that.raffleSettings.data_grouping,
						company_id: that.raffleSettings.data_grouping == 1 ? 0 : raffleWinner.company_id,
						multiple: 0,
						event_id: that.GET_EVENT_ID
					}
				
					that.$store.dispatch('urlPost', payloadSave).then((res)=>{
						that.fetchData(1)
					}).catch((err) => {
						console.log(err);
						Swal.close();
						Swal.fire(
							"",
							err.responseJSON && err.responseJSON.message,
							"error"
						).then((result) => {
							if (result.isConfirmed) {
							// window.location.reload();
							Swal.close();
							}
						});
					});

					animatedBall.remove();
					rightDoor.removeClass("animation");
					leftDoor.removeClass("animation");

					animatedBall.remove();
					rightDoor.removeClass("animation");
					leftDoor.removeClass("animation");

					that.winnerList.push({
						id: idRanking,
						name: selectedName,
						prize: rafflePrizes[currentPrizeIndex].value,
						prize: rafflePrizes[currentPrizeIndex].code,
						winner_id: that.raffleSettings.data_grouping == 1 ? employeedataID : raffleWinner.ticket_num,
						prize_id: rafflePrizes[currentPrizeIndex].id,
					});

					if(that.winnerList.length > 0){
						that.$store.commit('DIALOG', true);
						let item = {
							full_name: selectedName,
							prize_image: rafflePrizes[currentPrizeIndex].prize_image,
							prize_name: rafflePrizes[currentPrizeIndex].name,
							suspense_animate: rafflePrizes[currentPrizeIndex].suspense_animate,
							company: company,
							refNumber: refNumber,
						}
						that.$store.commit('DIALOG_VIEW', item);
						

						// setTimeout(() => {
						// 	that.$store.commit('DIALOG', false);
						// 	that.$store.commit('DIALOG_VIEW', {});
						// }, 5000);
							// that.currentPrizeIndex++
					}
					// console.log("currentPrizeIndex before increment: " + currentPrizeIndex);
					// currentPrizeIndex++;
					// console.log("currentPrizeIndex after increment: " + currentPrizeIndex);
					// displayWinners();
					// getNextRaffleParticipants(
					// 	rafflePrizes[currentPrizeIndex].code
					// );
					resolve();

						let payload = {
							url: 'official-winners',
							winnerData: that.winnerList,
							data_grouping: that.raffleSettings.data_grouping,
						}
						that.$store.dispatch('urlPost', payload).then((response)=>{
						}).catch((error)=>{
							console.error(error)
						})
						// that.fetchData()
					},
				});
				});
			}
			liftBallUp()
				.then(() => liftBallDown())
				.then(() => openDoors())
				.then(() => moveBallOut())
				.then(() => setTimeout(callback, 1000));
			}
			// Add an instance to the DOM tree
			draw(parent) {
				this.view.appendTo(parent);
				this.ctx.save();
				this.ctx.translate(0, 0);
				this.ctx.beginPath();
				this.ctx.fillStyle = "#ff0000";
				this.ctx.arc(70, 70, 70, 0, 2 * Math.PI);
				this.ctx.fill();
				this.drawBalls();
				return this;
			}
			// Очисить канвас
			_clearBalls() {
				this.ctx.clearRect(0, 0, this.opt.size, this.opt.size);
				return this;
			}
			clear() {
				this.view.remove();
				this.dom.canvas = null;
				this.balls = [];
				return this;
			}
		}
		// main variables
		let $scene = $(".scene");
		let $ballPlace = $("#ball-place");
		let $button = $("button#play");
		let ballAnimationOpt = {
			width: 70,
			height: 70,
			fontSize: 32,
		};
		// init LotteryMachine
		let lotteryMachine = new LotteryMachine(
			that.participants,
			that.rafflePrizes,
			that.currentPrizeIndex,
			that.winnerList,
			that.winners,
			that.employeeDatas
		);
		lotteryMachine.draw($scene);
		let handleClick = () => {
			// let lottoSound = document.getElementById('lottoSound');
			// lottoSound.play();
			let randomNumber = [
				Math.floor(Math.random() * that.GET_PARTICIPANT_LIST.length),
			];
			let winnerList = that.winnerList;
			let rafflePrizes = that.rafflePrizes;

			if (that.employeeDatas.length !== 0) {
				// that.currentPrizeIndex = +that.employeeDatas.length;
				that.employeeDatas.length = 0;
			}
			let currentPrizeIndex = that.currentPrizeIndex;
			let employeeDatas = that.employeeDatas;
			that.indexWatcher = currentPrizeIndex;
			let raffleWinner = that.GET_PARTICIPANT_LIST[randomNumber];
			// console.log("raffleWinner:", raffleWinner.employee_data_id);
			that.participants.splice(randomNumber, 1);
			// console.log(this.participants);
			$button.off("click");
			lotteryMachine
			.play()
			.then(() =>
				lotteryMachine.rollBallOut(
					$scene,
					$ballPlace,
					ballAnimationOpt,
					() => $button.on("click", handleClick),
					raffleWinner,
					rafflePrizes,
					currentPrizeIndex,
					winnerList,
					employeeDatas
				)
			);
		};
		$button.on("click", handleClick);
		// function getNextRaffleParticipants(prizeCode) {
		// 	let formData = new FormData();
		// 	formData.append("prize_code", prizeCode);
		// 	let payload = {
		// 		url: "getPrize-raffle-participants",
		// 	};
		// 	that.$store.dispatch("urlPost", payload).then((response) => {
		// 		that.participants = response;
		// 	}).catch((err) => {
		// 		console.log(err);
		// 		Swal.close();
		// 		Swal.fire(
		// 		"",
		// 		err.responseJSON && err.responseJSON.message,
		// 		"error"
		// 		).then((result) => {
		// 			if (result.isConfirmed) {
		// 				// window.location.reload();
		// 				Swal.close();
		// 			}
		// 		});
		// 	});
		// }
		// getNextRaffleParticipants("10th Prize");
		// let payload = {
		// 	url: "raffle-participants",
		// };
			// that.$store.dispatch("urlGet", payload).then((response) => {
			// 	that.raffleParticipants = response.raffleParticipants;
			// 	that.rafflePrizes = response.rafflePrizes;
			// 	var raffleParticipants = that.raffleParticipants;
			// 	var rafflePrizes = that.rafflePrizes;
			// 	// var rafflePrizes = <?php echo json_encode($rafflePrizes); ?>;
			// 	let winnerIDS = [];
			// 	let default_draw_type = 2;
			// 	$("#draw_type").val(default_draw_type);

			// 	$("#chart").addClass("d-none");
			// 	$(".scene").removeClass("d-none");
			// 	$("#lottery_col").addClass("col-lg-12");
			// 	$("#winners_col").removeClass("col-4");
			// 	$("#winners_col").addClass("col-5");

			// 	var data = raffleParticipants;
			
			// });
		});
	},
	destroyed() {
        window.removeEventListener('keyup', this.handleKeyPressRaffle);
    },
	watch:{
		participants:{
			handler(val){
			}
		},
		currentPrizeIndex:{
			handler(val){
			}
		},
		DIALOGS:{
			handler(val){
				if(!val){
					this.fetchData(1)
				}
			}
		}
	}
};
</script>

<style scoped>
	#stage {
		margin: 0 auto;
		width: 600px;
		padding: 0 0 40px;
	}
		.perspective-on {
		-webkit-perspective: 1000px;
		-moz-perspective: 1000px;
		perspective: 1000px; /* Setting the perspective of the contents of the stage but not the stage itself*/
	}
	.perspective-off {
		-webkit-perspective: 0;
		-moz-perspective: 0;
		perspective: 0;
	}
	#rotate {
	margin: 0 auto 0;
	width: 450px;
	height: 220px;
	padding-top: 200px;
	/* Ensure that we're in 3D space */
	transform-style: preserve-3d;
	}
	.ring {
	margin: 0 auto;
	height: 80px;
	width: 90px;
	float: left;
	transform-style: preserve-3d;
	}
	.slot {
	position: absolute;
	width: 90px;
	height: 80px;
	box-sizing: border-box;
	opacity: 0.9;
	color: rgba(0, 0, 0, 0.9);
	background: #fff;
	border: solid 2px #000;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	}
	.backface-on {
	-webkit-backface-visibility: visible;
	-moz-backface-visibility: visible;
	backface-visibility: visible;
	}
	.slot p {
	font-size: 36px;
	font-weight: bold;
	line-height: 80px;
	margin: 0;
	text-align: center;
	}
	.go {
	display: block;
	margin: 0 auto 20px;
	padding: 10px 30px;
	font-size: 20px;
	cursor: pointer;
	}
	full_name {
	cursor: pointer;
	display: inline-block;
	width: 45%;
	text-align: center;
	}
	.tilted {
	transform: rotateY(45deg);
	}
	.spin-0 {
	transform: rotateX(-3719deg);
	}
	.spin-1 {
	transform: rotateX(-3749deg);
	}
	.spin-2 {
	transform: rotateX(-3779deg);
	}
	.spin-3 {
	transform: rotateX(-3809deg);
	}
	.spin-4 {
	transform: rotateX(-3839deg);
	}
	.spin-5 {
	transform: rotateX(-3869deg);
	}
	.spin-6 {
	transform: rotateX(-3899deg);
	}
	.spin-7 {
	transform: rotateX(-3929deg);
	}
	.spin-8 {
	transform: rotateX(-3959deg);
	}
	.spin-9 {
	transform: rotateX(-3989deg);
	}
	.spin-10 {
	transform: rotateX(-4019deg);
	}
	.spin-11 {
	transform: rotateX(-4049deg);
	}
	@keyframes back-spin {
	100% {
		transform: rotateX(30deg);
	}
	}
	@keyframes tiltin {
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(45deg);
	}
	}
	@keyframes tiltout {
	0% {
		transform: rotateY(45deg);
	}
	100% {
		transform: rotateY(0deg);
	}
	}

	@keyframes spin-0 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3719deg);
	}
	}
	@keyframes spin-1 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3749deg);
	}
	}
	@keyframes spin-2 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3779deg);
	}
	}
	@keyframes spin-3 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3809deg);
	}
	}
	@keyframes spin-4 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3839deg);
	}
	}
	@keyframes spin-5 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3869deg);
	}
	}
	@keyframes spin-6 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3899deg);
	}
	}
	@keyframes spin-7 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3929deg);
	}
	}
	@keyframes spin-8 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3959deg);
	}
	}
	@keyframes spin-9 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-3989deg);
	}
	}
	@keyframes spin-10 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-4019deg);
	}
	}
	@keyframes spin-11 {
	0% {
		transform: rotateX(30deg);
	}
	100% {
		transform: rotateX(-4049deg);
	}
	}
	text {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 11px;
	pointer-events: none;
	}
	#chart {
	width: 500px;
	height: 500px;
	top: 0;
	left: 0;
	}
	#question {
	position: absolute;
	width: 400px;
	height: 500px;
	top: 0;
	left: 520px;
	}
	#question h1 {
	font-size: 50px;
	font-weight: bold;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: absolute;
	padding: 0;
	margin: 0;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	}
	button#play  {
		font-family: "Open Sans";
		color: #fff;
		text-align: center;
		position: absolute;
		top: 345px;
		left: 160px;
		font-size: 18px;
		border: none;
		width: 158px;
		border-radius: 3px;
		padding: 10px;
		background-color: #ffa500;
		box-sizing: border-box;
		cursor: pointer;
	}
	button#start  {
		font-family: "Open Sans";
		color: #fff;
		text-align: center;
		position: absolute;
		top: 345px;
		left: 160px;
		font-size: 18px;
		border: none;
		width: 158px;
		border-radius: 3px;
		padding: 10px;
		background-color: #ffa500;
		box-sizing: border-box;
		cursor: pointer;
	}
	.scene {
	font-family: "Open Sans";
	}
	#lottery {
	position: relative;
	}
	#ball-place {
	position: absolute;
	top: 0px;
	left: 160%;
	}
	@media (min-width: 1480px) {
	#ball-place {
		position: absolute;
		top: 0px;
		left: 160%;
	}
	}
	.lottery-machine {
	position: inherit;
	border-radius: 100%;
	width: 200px;
	height: 0.1px;
	}
	.lottery-machine-canvas {
	position: inherit;
	top: 0px;
	width: 700px;
	height: 700px;
	padding: 7px;
	border-radius: inherit;
	box-sizing: border-box;
	transition: opacity 0.7s;
	}
	.lottery-machine::before {
	content: "";
	position: absolute;
	top: 0px;
	width: 650px;
	left: -5px;
	height: 650px;
	background-color: rgba(0, 0, 0, 0.3);
	border: 7px solid #ffa500;
	border-radius: inherit;
	box-sizing: border-box;
	transition: opacity 0.7s;
	animation: neonGlow 1s infinite alternate;
	background-position: center;
	}
	.lottery-machine::after {
		content: "";
		position: absolute;
		top: 0px;
		width: 650px;
		left: -5px;
		height: 650px;
		border-radius: inherit;
		box-sizing: border-box;
		transition: opacity 0.7s;
		background-position: center;
		background-image:
			/* First image */ url("../../src//assets/Raffle jar.png"); /* Second image */
		background-size: /* Size for INGCONITE.png */ 700px; /* Size for masqqpng.png */
		z-index: -1;
	}
	@keyframes neonGlow {
	from {
		box-shadow: 0 0 0px 0px #ffa500;
	}
	to {
		box-shadow: 0 0 5px 1px #8f1475;
	}
	}
	.lottery-machine.disabled .lottery-machine-canvas {
	opacity: 0;
	pointer-events: none;
	}
	::v-deep .lottery-machine.disabled::after {
	opacity: 0;
	pointer-events: none;
	}
	/*
		.lottery-machine .lottery-base {
		position: absolute;
		background-image: url('https://svgur.com/i/DH4.svg');
		width: 165px;
		height: 75px;
		left: 0;
		top: 141px;
		z-index: 2;
		} */
	.lottery-machine .lottery-lever {
	position: absolute;
	background-image: url("https://svgur.com/i/DGD.svg");
	width: 830px;
	height: 23px;
	left: 365px;
	top: 650px;
	transition: transform 0.5s;
	transform-origin: left center;
	}
	.lottery-machine .lottery-lever.hover {
	transform: rotate(-10deg);
	}
	.lottery-machine .lottery-lever.down {
	transform: rotate(20deg);
	}
	/* .lottery-machine .lottery-tube {
		position: absolute;
		background-image: url('https://svgur.com/i/DHH.svg');
		width: 110px;
		height: 182px;
		left: 61px;
		top: 3px;
		z-index: 1;
		} */
	.lottery-machine .lottery-right-door,
	.lottery-machine .lottery-left-door {
	position: absolute;
	background-image: url("https://svgur.com/i/DJ5.svg");
	width: 50px;
	height: 29px;
	left: 16px;
	margin-left: 300px;
	margin-top: 235px;
	top: 420px;
	z-index: 4;
	transition: left 0.75s;
	}
	.lottery-machine .lottery-right-door.animation,
	.lottery-machine .lottery-left-door.animation {
	left: 25px;
	}
	.lottery-machine .lottery-left-door {
	background-image: url("https://svgur.com/i/DHF.svg");
	left: -14px;
	}
	.lottery-machine .lottery-left-door.animation {
	left: -25px;
	}
	.lottery-machine .lottery-lift {
	position: absolute;
	background-color: #f00000;
	width: 13px;
	height: 0;
	left: 310px;
	top: 675px;
	box-sizing: border-box;
	border: 1px solid #ffa500;
	border-radius: 2px;
	transition: top 1s, height 1s;
	z-index: 1;
	}
	.lottery-machine .lottery-lift.animation {
	top: 0px;
	height: 600px;
	}
	::v-deep .lottery-machine .lottery-ball {
	width: 15px; /* Increase size for better visibility */
	height: 15px; /* Increase size for better visibility */
	line-height: 1.39;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	border-radius: 50%;
	position: absolute;
	left: 309px;
	top: 659px;
	transition: top 1s, transform 0.75s ease-in;
	background-size: 200% auto;
	transition: top 1s, transform 1s ease-in;
	}

	::v-deep .lottery-machine .lottery-ball.invisible {
	opacity: 100;
	}
	::v-deep .lottery-machine .lottery-ball.animation-up {
	top: -12px;
	}
	::v-deep .lottery-machine .lottery-ball.animation-roll {
	transform-origin: 7.7px 343px;
	transform: rotate(180deg);
	}

	::v-deep .lottery-machine .lottery-ball.no-transition {
	transition: none;
	}
	::v-deep .lottery-machine .lottery-hover-area {
	position: absolute;
	top: 0;
	left: 0;
	height: 214px;
	width: 100%;
	z-index: 5;
	}
	::v-deep .lottery-ball.clone {
	position: absolute;
	width: 18px;
	height: 18px;
	font-size: 12px;
	line-height: 1.375;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	}
	::v-deep .lottery-ball.clone.invisible {
	opacity: 0;
	}
	::v-deep .lottery-ball.clone.no-transition {
	transition: none;
	}
	#play {
	margin-top: 360px;
	margin-left: 90px;
	}
	#WinnerListButton {
	position: flex;
	top: 15px;
	left: 1390px;
	width: 15px;
	}

	@media (min-width: 1400px) {
	#WinnerListButton {
		left: 72%;
		transform: translateX(-50%);
	}
	}

	@media (max-width: 1399px) and (min-width: 1200px) {
	#WinnerListButton {
		left: 1380px;
	}
	}
	#downloadWinnersBtn {
	width: 15px;
	top: 17px;
	left: 10px;
	z-index: 1;
	}
	@keyframes glassEffect {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
	}
	.glass-animation {
	animation: glassEffect 2s infinite linear;
	background-image: linear-gradient(
		270deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.6),
		rgba(255, 255, 255, 0)
	);
	background-size: 200% 100%;
	transition: background-position 0.3s;
	}
	.winner-list-img {
	position: absolute;
	top: 0;
	right: 550px;
	max-width: 18%;
	max-height: auto;
	}

	@media (min-width: 1400px) {
	.winner-list-img {
		left: 62%;
		transform: translateX(-50%);
	}
	}

	@media (max-width: 1399px) and (min-width: 1200px) {
	.winner-list-img {
		left: 1380px;
	}
	}
	body {
		overflow: hidden;
	}
	.container {
		overflow: hidden;
	}
   	h4 ,h1 , h5, h2 {
        font-family: 'Kaushan Script', cursive;
        background: -webkit-linear-gradient(#ae6901, #eddb77);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

	.raffle-button {
    animation: pulse 1.5s infinite alternate, colorChange 5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(2);
    }
    100% {
        transform: scale(3);
    }
}


</style>
<!-- @keyframes colorChange {
    0% {
        color: rgb(0, 0, 0); /* Change to the initial color */
    }
    50% {
        color: rgb(0, 183, 255); /* Change to the color halfway through the animation */
    }
    100% {
        color: rgb(229, 255, 158); /* Change to the final color */
    }
} -->
