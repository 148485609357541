<template>
    <v-card>
        <v-card-title>
        </v-card-title>
        <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-row>
                        <!-- <v-col cols="12" sm="3">
                            <v-switch label="Start Nomination Date" v-model="form.nomination_start" :false-value="0" :true-value="1"></v-switch>
                            <v-text-field
                                v-model="form.nomination_start_date"
                                outlined
                                readonly
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-switch label="End Nomination Date" :disabled="form.nomination_start == 0" v-model="form.nomination_end" :false-value="0" :true-value="1"></v-switch>
                            <v-text-field
                                v-model="form.nomination_end_date"
                                outlined
                                readonly
                                dense
                            >
                            </v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="3">
                            <v-switch label="Start Voting Date"  v-model="form.voting_start" :false-value="0" :true-value="1"></v-switch>
                            <v-text-field
                                v-model="form.voting_start_date"
                                outlined
                                readonly
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-switch label="End Voting Date" :disabled="form.voting_start == 0 ? true: false" v-model="form.voting_end" :false-value="0" :true-value="1"></v-switch>
                            <v-text-field
                                v-model="form.voting_end_date"
                                outlined
                                readonly
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: ['event_id'],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            tab: null,
            items:[],
            settings_form:[],
            job_title_selection :[],
            form:{
                nomination_start: 0,
                nomination_start_date: null,
                nomination_end: 0,
                nomination_end_date: null,

                voting_start: 0,
                voting_start_date: null,
                voting_end: 0,
                voting_end_date: null,
            },
            monthsArray: [],
            currentSettings: [],
            isMounted: false,
            votingIsDisabled: false,
        }
    },
    async mounted(){
        await this.getAllNominationSettings();
        this.getFilters()
        this.checkEndNomination();
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_NEW_DATA',
            'GET_EVENT_ID',
            // 'GET_FORM_SETTINGS'
        ])
    },
    methods:{
        async getAllNominationSettings(filter = null){
            this.initialSettings();
            this.loaded = false;
            let conditions = ["-", ":"]
            let payload = {
                type: 'Nomination',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
                this.currentSettings = res
                await Promise.all(res.map(async (e) => {
                    await Promise.all(Object.keys(this.form).filter(async (el) => {
                        if (el == e.parameter) {
                            if (conditions.some((el) => e.value.includes(el))) {
                            this.form[el] = e.value;
                            } else {
                            this.form[el] = parseFloat(e.value);
                            }
                        }
                    }));
                }));
                this.isMounted = true;
            })
        },
        getFilters(){
            let filters = {
                data: this.form,
                type:'Nomination'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
        checkEndNomination(){
            let payload = {
                url: 'checkSettingsByParameter',
                parameter: 'nomination_end_date',
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                if(res == 0){
                    this.votingIsDisabled = true;
                }else{
                    this.votingIsDisabled = false;
                }
            })
        },
        initialSettings(){
            this.form = {
                nomination_start: 0,
                nomination_start_date: null,
                nomination_end: 0,
                nomination_end_date: null,

                voting_start: 0,
                voting_start_date: null,
                voting_end: 0,
                voting_end_date: null,
            }
        }
    },
    watch:{
        // GET_FORM_SETTINGS:{
        //     handler(val){
        //         this.items = val

        //         val.forEach(e=>{
        //             Object.keys(this.form).filter(el=>{
        //                 if(el == e.parameter){
        //                     this.form[el] = parseFloat(e.value)
        //                 }
        //             });
        //         })
        //     }
        // },
        form:{
            handler(val){
                this.getFilters()
            },
            deep:true
        },
        'form.nomination_start':{
            handler(val){
                if(this.isMounted){
                    if(val){
                        this.form.nomination_start_date = moment().format('YYYY-MM-DD HH:mm:ss');
                    }else{
                        this.form.nomination_start_date = null;
                        this.form.nomination_end = 0;
                    }
                }
            }
        },
        'form.nomination_end':{
            handler(val){
                if(this.isMounted){
                    if(val){
                        this.form.nomination_end_date = moment().format('YYYY-MM-DD HH:mm:ss');
                    }else{
                        this.form.nomination_end_date = null;
                        this.form.voting_start = 0
                        this.form.voting_start_date = null
                        this.form.voting_end = 0
                        this.form.voting_end_date = null
                    }
                }
            }
        },
        'form.voting_start':{
            handler(val){
                if(this.isMounted){
                    if(val){
                        this.form.voting_start_date = moment().format('YYYY-MM-DD HH:mm:ss');
                    }else{
                        this.form.voting_start_date = null;
                        this.form.voting_end = 0;
                        this.form.voting_end_date = null
                    }
                }
            }
        },
        'form.voting_end':{
            handler(val){
                if(this.isMounted){
                    if(val){
                        this.form.voting_end_date = moment().format('YYYY-MM-DD HH:mm:ss');
                    }else{
                        this.form.voting_end_date = null;
                    }
                }
            }
        },
        GET_NEW_DATA:{
            handler(val){
                if(val){
                    this.checkEndNomination();
                    this.$store.commit('NEW_DATA', false);
                }
            }
        },
        GET_EVENT_ID:{
            async handler(val){
                if(val){
                    await this.getAllNominationSettings();
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
</style>
