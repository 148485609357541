<template>
  <v-app :height="height" style="min-height: 100vh; background-color: black;">
    <!-- <Header @toggle-sheet="toggleSheet" /> -->
      <div id="app">
          <!-- <HelloWorld v-if="showHelloWorld && !$route.path.includes('/register') && !$route.path.includes('/confirmation') && !$route.path.includes('/raffle') && !$route.path.includes('/landing') && !$route.path.includes('/dashboard') && !$route.path.includes('/ConfirmationFillUp') && !$route.path.includes('/nomination')"  /> -->
          <router-view/>
      </div>
  </v-app>
</template>


<script>
  import HelloWorld from './components/HelloWorld.vue';
  import Header from './components/Header.vue';
  import './assets/css/scrollbar.css';
	import Swal from 'sweetalert2';

  export default {
    components: {
      HelloWorld,
      Header,
    },
    name: 'App',
    data: () => ({
      sheet: false,
      showHelloWorld: true, // Initially set to true to show HelloWorld
    }),
    computed: {
      height() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220;
          case 'sm': return 400;
          case 'md': return 500;
          case 'lg': return 600;
          case 'xl': return 800;
        }
      },
    },
    methods: {
      toggleSheet() {
        if (this.$route.path.includes('/confirmation || /raffle || /INGCONight')) {
          this.showHelloWorld = false; // Hide HelloWorld when confirm registration button is clicked on /confirmation route
        } else {
          this.sheet = !this.sheet;
          this.toggleHelloWorld();
        }
      },
      closeSheet() {
        this.sheet = false;
      },
      toggleHelloWorld() {
        this.showHelloWorld = !this.showHelloWorld; // Toggle the showHelloWorld flag
      },
    },
    mounted(){
        // this.$store.dispatch("credentials").then((res)=>{

        // }).catch((error)=>{
        //     console.log(error.response)
        //     if(error.response.status == 401){
        //         Swal.fire({
        //             title: 'Unauthenticated!',
        //             text: '',
        //             icon: 'error',
        //             allowOutsideClick: false
        //         }).then((result)=>{
        //             if(result.isConfirmed){
        //                 localStorage.removeItem("token");
        //                 router.push('/dashboard');
        //             }
        //         })
        //     }
        // });
    }
  };
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Kaushan+Script&display=swap');


</style>

