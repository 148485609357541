import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './components/routes/routes';
import store from './store/modules';
import moment from 'moment';
window.Moment = moment;



Vue.config.productionTip = false
window.$ = window.jQuery = require('jquery');
Object.defineProperty(Vue.prototype, 'Lodash', { value: window._ });

Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery'); // you'll have this.$jQuery anywhere in your vue project
  }
});

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
      return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('formatDate', function (value) {
if (value) {
  return moment(String(value)).format('MM/DD/YYYY')
}
});

Vue.filter('formatDateTime', function (value) {
if (value) {
  return moment(String(value)).format('MM/DD/YYYY hh:mm A')
}
});

Vue.filter('formatDateTime24hours', function (value) {
if (value) {
  return moment(String(value)).format('MM/DD/YYYY HH:mm')
}
});

Vue.filter('formatMonth', function (value) {
if (value) {
  return moment(String(value)).format('MM-YYYY')
}
});

Vue.filter('formatMonthSoa', function (value) {
if (value) {
  return moment(String(value)).format('MMM YYYY')
}
});

Vue.filter('thousandSeprator', function (value) {
if (value !== '' || value !== undefined || value !== 0 || value !== '0' || value !== null) {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
} else {
    return value;
}
});

Vue.filter('capitalize', function (value) {
if (!value) return '';
return value.replace(/\w\S*/g, function (txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
});
});

Vue.filter('uppercase', function (value) {
if (!value) return '';
return value.toUpperCase()
})

Vue.filter('toWords', function (value) {
if (!value) return '';
return converter(value,'peso','cent');
})

Vue.filter('percentTwoDecimals', function (value, decimal_places = 2) {
  if (!value) {
      value = 0
  }

  value = value * 100
  value = Math.round(value * Math.pow(10, decimal_places)) / Math.pow(10, decimal_places)
  value = value + '%'
  return value
})

Vue.filter('formatDateWithDay', function (value) {
if (value) {
  return moment(String(value)).format('dddd (MMMM D, YYYY)');
}
});

Vue.filter('formatLongDate', function (value) {
if (value) {
  return moment(String(value)).format('MMMM D, YYYY');
}
});

Vue.filter('formatDateYMD', function (value) {
if (value) {
  return moment(String(value)).format('YYYY-MM-DD');
}
});

Vue.filter('formatDateYM', function (value) {
if (value) {
  return moment(String(value)).format('YYYY-MM');
}
});

Vue.filter('formatDateDay', function (value) {
if (value) {
  return moment(String(value)).format('dddd');
}
});

new Vue({
  router,
  vuetify,
  store,
  moment,
  jQuery,
  render: h => h(App)
}).$mount('#app')



