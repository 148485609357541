<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="overflow-x-hidden">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom">
                <span class="text-h5">Event Sitemap Image</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 mt-1" fluid>
                    <v-row no-gutters>
                        <v-col cols="12" class="pa-0 pb-2">
                            <v-hover v-model="isHover">
                                <template v-slot="{ hover }">
                                    <v-card style="border: #444444 2px solid;">
                                        <v-container fluid class="picture">
                                            <v-img :src="form.sitemap_image" contain aspect-ratio="1.7">
                                            </v-img>
                                        </v-container>
                                        <v-fade-transition style="opacity:0.5;">
                                            <v-overlay absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                                                    <v-row>
                                                        <v-tooltip bottom color="#2590ff">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    v-show="hover == true"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    :disabled="disabled_view"
                                                                    style="opacity:1; cursor: pointer;"
                                                                    class="ma-2"
                                                                    color="#2590ff"
                                                                    text
                                                                >
                                                                    <label for="upload-file"><span class="upload-file"><v-icon x-large>mdi-tray-arrow-up</v-icon></span></label>
                                                                </v-btn>
                                                            </template>
                                                            <span>Upload Image</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                            
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.order"
                                :disable="disabled_view"
                                label="Order"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #000000"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addeventSitemap() : updateRafflePrize()"
                            :disabled="disabled_view"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: true
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_EVENT_ID"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getFlowImagesData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getFlowImagesData();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
    },
    methods: {
        initialForm() {
            this.form = {
                order      : 1,
                sitemap_image : ''
            };
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async addeventSitemap() {
            this.$refs.form.validate();

            let payload = {
                url: "eventSitemap",
                ...this.form,
                event_id: this.GET_EVENT_ID
            }
            await this.$store.dispatch('urlPost', payload).then(response=>{
                Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true);
                this.$store.commit("DIALOG", false);
            })
        },
        async updateRafflePrize() {
            let payload = {
                url: "eventSitemap",
                id: this.form.id,
                ...this.form
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
				this.closeDialog();
			});
        },
        async getFlowImagesData() {
            this.form.id                    = this.GET_DIALOG_VIEW.id;
            this.form.order                  = this.GET_DIALOG_VIEW.order;
            this.form.sitemap_image        = this.GET_DIALOG_VIEW.sitemap_image;
        },
        uploadImage(event) {
            let that = this;
			let reader = new FileReader()
			reader.addEventListener('load', () => {
				that.form.sitemap_image = reader.result
			})
			let file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        getLatestOrderSitemap(){
            let payload = {
                url: 'getLatestOrderSitemap',
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                this.form.order = res;
            });
        },
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }else{
                    if(this.ACTION === 'Submit'){
                        this.getLatestOrderSitemap()
                        this.isHover = true;
                    }
                }
            },
        },
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
